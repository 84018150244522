import { LeftSideBar } from "../components/LeftSidebar/LeftSideBar";
import { useAtom } from "jotai";
import { toastAtom, visibleLeftSidebarAtom } from "../app-jotai";
import { Toast } from "../../packages/excalidraw/components/Toast";

interface IRootLayoutProps {
  children: React.ReactNode;
}

export const RootLayout = ({ children }: IRootLayoutProps) => {
  const [isVisible] = useAtom(visibleLeftSidebarAtom);
  const [toast, setToast] = useAtom(toastAtom);

  return (
    <>
      <LeftSideBar />
      <div
        className="box-border flex h-full w-full flex-col overflow-auto p-3 transition-[margin] relative !mt-0 !overflow-hidden bg-editor !p-0 mt-0 px-12 pb-12 pt-6"
        style={{ marginLeft: isVisible ? "260px" : 0 }}
      >
        {children}
      </div>
      {toast.isShow && (
        <Toast
          message={toast.message}
          closable={toast.closable}
          onClose={() => {
            setToast({ ...toast, isShow: false });
            toast.onClose && toast.onClose();
          }}
        />
      )}
    </>
  );
};
