export default function Copied() {
  return (
    <span className="inline-flex flex-shrink-0 items-center rounded-full px-2 py-0.5 text-xs font-medium bg-green-200 text-green-800 dark:bg-green-700 dark:text-green-100">
      <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        className="mr-0.5 Icon Icon-check"
        viewBox="0 0 24 24"
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        stroke-linecap="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>{" "}
      copied
    </span>
  );
}
