import { WorkspaceSelector } from "./WorkspaceSelector";
import SidebarFooter from "./SidebarFooter";
import CollectionList from "./CollectionList";
import ToolBar from "./ToolBar";
import { useAtomValue } from "jotai";
import { visibleLeftSidebarAtom } from "../../app-jotai";
import { useMatchRoute } from "../../pages/_routing/utils";
import { PUBLIC_PATHS } from "../../pages/_routing/router";

export function LeftSideBar() {
  const isVisible = useAtomValue(visibleLeftSidebarAtom);
  const { matchPath } = useMatchRoute();
  const isShow = isVisible && !PUBLIC_PATHS.includes(matchPath);
  return (
    <div
      className="fixed z-20 transition-[left] ease-in-out flex h-full w-full flex-col border-r border-surface-high bg-white dark:bg-[#1B1B1F] overflow-x-hidden"
      style={{ maxWidth: "260px", left: isShow ? 0 : "-260px" }}
    >
      <WorkspaceSelector />
      <div className="x-custom-scrollbar flex-1 overflow-y-auto overscroll-none">
        <ToolBar />
        <CollectionList />
      </div>

      <SidebarFooter />
    </div>
  );
}
