import { useAtomValue, useSetAtom } from "jotai";
import { workspaceService } from "../services";
import { workspaceAtom, workspaceIdAtom } from "../../app-jotai";

export default function useRefreshWorkspace() {
  const setWorkspaceAtom = useSetAtom(workspaceAtom);
  const workspaceId = useAtomValue(workspaceIdAtom);
  return async () => {
    const { data: workspace } = await workspaceService.getUserWorkspace({
      workspaceId,
    });
    if (workspace) {
      setWorkspaceAtom(workspace);
      localStorage.setItem("workspaceId", workspace.id);
    }
  };
}
