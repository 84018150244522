export default function MyWorkspace() {
  return (
    <>
      <div className="MyWorkspace-cont">
        <h2 className="heading my-3 text-xl font-bold">
          My workspaces
          <a className="link" href="/workspace/create/name">
            Create new workspace
          </a>
        </h2>

        <div className="row">
          <div className="row-avatar">
            <img
              className="pointer-events-none !select-none rounded-full object-contain hover:brightness-95 active:brightness-75 "
              src="https://app.excalidraw.com//letter-avatars/t_indigo.png"
              alt="test"
              referrerPolicy="no-referrer"
              style={{
                width: "45px",
                height: "45px",
              }}
            />
            <span className="text">test</span>
          </div>

          <div className="row-role">admin</div>

          <div className="links">
            <a className="link" href="/o/3rzG1yGrEM">
              Visit
            </a>
            <a className="link" href="/o/3rzG1yGrEM/settings">
              Settings
            </a>
            <a className="link" href="/o/3rzG1yGrEM/settings/billing">
              Billing
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
