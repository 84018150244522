import { useContext } from "react";
import RouterContext from "./RouterContext";
import clsx from "clsx";
export interface ILinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export default function Link({
  to,
  children,
  className,
  style = {},
  onClick,
}: ILinkProps): JSX.Element {
  const { navigate } = useContext(RouterContext);
  return (
    <a
      href={to}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
        if (!to) {
          return;
        }
        navigate(to);
      }}
      className={clsx("", className)}
      style={style}
    >
      {children}
    </a>
  );
}
