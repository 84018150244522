import { useState } from "react";
import { PrivateCollectionIcon } from "../../../packages/excalidraw/components/icons";
import { CreateCollectionModal } from "../Modals/CreateCollectionModal";
import CollectionItem from "./CollectionItem";
import { useSelectedItem } from "../../share/hook/useSelectedItem";
import {
  collectionListAtom,
  privateCollectionAtom,
  workspaceIdAtom,
} from "../../app-jotai";
import { useAtomValue } from "jotai";
import useSetSelectCollection from "../../share/hook/useSetSelectCollection";
import { SIDEBAR_ITEM } from "../../share/constants";

export default function CollectionList() {
  const collections = useAtomValue(collectionListAtom);
  const [open, setOpen] = useState(false);
  const { isSelected } = useSelectedItem();
  const workspaceId = useAtomValue(workspaceIdAtom);
  const setSelectCollection = useSetSelectCollection();
  const privateCollection = useAtomValue(privateCollectionAtom);
  return (
    <>
      <div className="mb-6 mt-2 px-2 pb-5 pt-2">
        <div className="flex items-center justify-between px-1">
          <div className="flex text-lg font-bold text-primary">Collections</div>
          <button
            type="button"
            className="h-6 w-6 rounded flex items-center justify-center bg-primary hover:bg-brand-hover active:bg-brand-active disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest text-base active:scale-[.98]"
            onClick={() => setOpen(true)}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className=" Icon "
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>

        <div className="-mx-1 rounded-md border border-solid border-transparent p-1 ">
          <CollectionItem
            name="Private"
            icon={<PrivateCollectionIcon />}
            editable={false}
            focused={isSelected(privateCollection.id)}
            to={`/w/${workspaceId}/${SIDEBAR_ITEM.PRIVATE_COLLECTION}`}
            onClick={() => setSelectCollection(privateCollection.id)}
          />
          <div className="mb-4 mt-1 h-[1px] w-full bg-gray-10"></div>
          <div className="flex flex-col">
            {collections
              .filter((collection) => !collection.isPrivate)
              .map((collection) => (
                <CollectionItem
                  key={collection.id}
                  name={collection.name}
                  focused={isSelected(collection.id)}
                  to={`/w/${workspaceId}/${collection.id}`}
                  onClick={() => setSelectCollection(collection.id)}
                />
              ))}
          </div>
        </div>
      </div>
      <CreateCollectionModal open={open} setOpen={setOpen} />
    </>
  );
}
