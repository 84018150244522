import type { Socket } from "socket.io-client";
import { MIME_TYPES } from "../../packages/excalidraw";
import { decompressData } from "../../packages/excalidraw/data/encode";
import type {
  ExcalidrawElement,
  FileId,
  OrderedExcalidrawElement,
} from "../../packages/excalidraw/element/types";
import type Portal from "../collab/Portal";
import { hashElementsVersion } from "./../../packages/excalidraw/element/index";
import type {
  AppState,
  BinaryFileData,
  BinaryFileMetadata,
  DataURL,
} from "./../../packages/excalidraw/types";
import { getSyncableElements, type SyncableExcalidrawElement } from ".";
import type { IStorageBackend } from "./StorageBackend";
import type { RemoteExcalidrawElement } from "../../packages/excalidraw/data/reconcile";
import { reconcileElements } from "../../packages/excalidraw/data/reconcile";
import { sceneService } from "../share/services/scene.service";

const HTTP_STORAGE_BACKEND_URL = import.meta.env.VITE_HTTP_STORAGE_BACKEND_URL;

class HttpStorageSceneVersionCache {
  private static cache = new WeakMap<Socket, number>();
  static get = (socket: Socket) => {
    return HttpStorageSceneVersionCache.cache.get(socket);
  };
  static set = (
    socket: Socket,
    elements: readonly SyncableExcalidrawElement[],
  ) => {
    HttpStorageSceneVersionCache.cache.set(
      socket,
      hashElementsVersion(elements),
    );
  };
}

export const isSavedToHttpStorage = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = hashElementsVersion(elements);

    return HttpStorageSceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};

export const saveToHttpStorage: IStorageBackend["saveToStorage"] = async (
  portal: Portal,
  elements: readonly SyncableExcalidrawElement[],
  appState: AppState,
) => {
  const { roomId, roomKey, socket } = portal;
  if (
    !roomId ||
    !roomKey ||
    !socket ||
    isSavedToHttpStorage(portal, elements)
  ) {
    return null;
  }
  const { data } = await sceneService.getSence<OrderedExcalidrawElement>(
    roomId,
  );
  if (!data?.value) {
    return null;
  }
  const prevStoredScene = data.value;
  let storedScene: SyncableExcalidrawElement[] = [...elements];
  if (prevStoredScene) {
    const prevStoredElements = getSyncableElements(prevStoredScene);
    const reconciledElements = getSyncableElements(
      reconcileElements(
        elements,
        prevStoredElements as OrderedExcalidrawElement[] as RemoteExcalidrawElement[],
        appState,
      ),
    );
    storedScene = reconciledElements;
  }
  await sceneService.update(roomId, {
    value: storedScene,
  });

  const storedElements = getSyncableElements(storedScene);

  HttpStorageSceneVersionCache.set(socket, storedElements);

  return storedElements;
};

export const loadFromHttpStorage: IStorageBackend["loadFromStorage"] = async (
  roomId: string,
  _roomKey: string,
  socket: Socket | null,
) => {
  const { data } = await sceneService.getSence<OrderedExcalidrawElement>(
    roomId,
  );

  if (!data?.value) {
    return null;
  }
  const storedElements = data.value;
  const elements = getSyncableElements(storedElements);
  if (socket) {
    HttpStorageSceneVersionCache.set(socket, elements);
  }

  return elements;
};

export const saveFilesToHttpStorage: IStorageBackend["saveFilesToStorage"] =
  async ({ prefix, files }) => {
    const erroredFiles = new Map<FileId, true>();
    const savedFiles = new Map<FileId, true>();

    await Promise.all(
      files.map(async ({ id, buffer }) => {
        try {
          const payloadBlob = new Blob([buffer]);
          const payload = await new Response(payloadBlob).arrayBuffer();
          await fetch(`${HTTP_STORAGE_BACKEND_URL}/files/${id}`, {
            method: "PUT",
            body: payload,
          });
          savedFiles.set(id, true);
        } catch (error: any) {
          erroredFiles.set(id, true);
        }
      }),
    );

    return { savedFiles, erroredFiles };
  };

export const loadFilesFromHttpStorage: IStorageBackend["loadFilesFromStorage"] =
  async (
    prefix: string,
    decryptionKey: string,
    filesIds: readonly FileId[],
  ) => {
    const loadedFiles: BinaryFileData[] = [];
    const erroredFiles = new Map<FileId, true>();

    await Promise.all(
      [...new Set(filesIds)].map(async (id) => {
        try {
          const response = await fetch(
            `${HTTP_STORAGE_BACKEND_URL}/files/${id}`,
          );
          if (response.status < 400) {
            const arrayBuffer = await response.arrayBuffer();
            const { data, metadata } = await decompressData<BinaryFileMetadata>(
              new Uint8Array(arrayBuffer),
              {
                decryptionKey,
              },
            );

            const dataURL = new TextDecoder().decode(data) as DataURL;

            loadedFiles.push({
              mimeType: metadata.mimeType || MIME_TYPES.binary,
              id,
              dataURL,
              created: metadata?.created || Date.now(),
            });
          } else {
            erroredFiles.set(id, true);
          }
        } catch (error: any) {
          erroredFiles.set(id, true);
          console.error(error);
        }
      }),
    );

    return { loadedFiles, erroredFiles };
  };
