import { useContext } from "react";
import RouterContext from "../../pages/_routing/RouterContext";
import * as Popover from "@radix-ui/react-popover";
import {
  ProfileIcon,
  SignOutIcon,
} from "../../../packages/excalidraw/components/icons";
import { authUserAtom } from "../../app-jotai";
import { useAtomValue } from "jotai";
import useLogout from "../../share/hook/useLogout";

const ProfileInfo = () => {
  const authUser = useAtomValue(authUserAtom);
  const onSignOut = useLogout();
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          type="button"
          id="radix-:r42:"
          aria-haspopup="menu"
          aria-expanded="false"
          data-state="closed"
          className="hover:bg-transparent active:bg-transparent"
        >
          <div className="flex items-center gap-2">
            <div className="navbar-avatar flex-shrink-0">
              <img
                className="pointer-events-none !select-none rounded-full object-contain hover:brightness-95 active:brightness-75 "
                src="https://avatars.githubusercontent.com/u/49265862?v=4"
                alt={authUser?.username}
                referrerPolicy="no-referrer"
                style={{
                  width: "36px",
                  height: "36px",
                }}
              />
            </div>

            <div className="max-w-[140px] truncate text-sm font-bold">
              {authUser?.username}
            </div>
          </div>
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className="z-30">
          <div className="navbar-user-dropdown flex min-w-[180px] select-none flex-col rounded-md bg-white px-1.5 py-2.5 shadow-context-menu dark:bg-surface-low">
            {/* <div className="separator"></div> */}
            <a
              className="item"
              role="menuitem"
              tabIndex={-1}
              data-orientation="vertical"
              data-radix-collection-item=""
              href="/account/my-profile"
            >
              <ProfileIcon />
              My Profile
            </a>

            <div className="separator"></div>
            <div className="flex flex-row items-center p-[10px] text-[13px]">
              Theme
              <div className="flex flex-row items-start rounded-[10px] border border-[#ddd] bg-island p-[3px] dark:border-[#151515] ml-auto">
                <div
                  className="relative flex h-5 w-7 flex-row items-center justify-center rounded-lg transition hover:text-primary"
                  title="Light theme"
                >
                  <input
                    name="theme"
                    type="radio"
                    aria-label="Light theme"
                    className="z-1 absolute m-0 h-full w-full cursor-pointer appearance-none p-0 opacity-0"
                  />
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className=" Icon "
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.996 19.01a.75.75 0 0 1 .743.649l.007.102v1.5a.75.75 0 0 1-1.493.101l-.007-.101v-1.5a.75.75 0 0 1 .75-.75Zm6.022-2.072 1.06 1.06a.75.75 0 1 1-1.06 1.061l-1.06-1.06a.75.75 0 0 1 1.06-1.061Zm-10.983 0a.75.75 0 0 1 0 1.06L5.974 19.06a.75.75 0 0 1-1.06-1.06l1.06-1.061a.75.75 0 0 1 1.06 0ZM12 6.475a5.525 5.525 0 1 1 0 11.05 5.525 5.525 0 0 1 0-11.05Zm0 1.5a4.025 4.025 0 1 0 0 8.05 4.025 4.025 0 0 0 0-8.05Zm9.25 3.293a.75.75 0 0 1 .102 1.493l-.102.007h-1.5a.75.75 0 0 1-.102-1.493l.102-.007h1.5Zm-17-.029a.75.75 0 0 1 .102 1.494l-.102.006h-1.5a.75.75 0 0 1-.102-1.493l.102-.007h1.5Zm1.64-6.37.084.072 1.06 1.06a.75.75 0 0 1-.976 1.134l-.084-.073-1.06-1.06a.75.75 0 0 1 .976-1.134Zm13.188.072a.75.75 0 0 1 .073.977l-.073.084-1.06 1.06a.75.75 0 0 1-1.133-.976l.072-.084 1.06-1.061a.75.75 0 0 1 1.061 0ZM12 1.99a.75.75 0 0 1 .743.648l.007.102v1.5a.75.75 0 0 1-1.493.101l-.007-.102v-1.5a.75.75 0 0 1 .75-.75Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div
                  className="relative flex h-5 w-7 flex-row items-center justify-center rounded-lg transition hover:text-primary"
                  title="Dark theme"
                >
                  <input
                    name="theme"
                    type="radio"
                    aria-label="Dark theme"
                    className="z-1 absolute m-0 h-full w-full cursor-pointer appearance-none p-0 opacity-0"
                  />
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className=" Icon "
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.026 17.001c-2.762 4.784-8.879 6.423-13.663 3.661a9.964 9.964 0 0 1-3.234-2.983.75.75 0 0 1 .365-1.131c3.767-1.348 5.785-2.911 6.956-5.146 1.232-2.353 1.551-4.93.689-8.464a.75.75 0 0 1 .769-.926 9.961 9.961 0 0 1 4.457 1.327C21.149 6.1 22.788 12.217 20.025 17Zm-8.248-4.903c-1.25 2.388-3.31 4.099-6.817 5.499a8.492 8.492 0 0 0 2.152 1.766 8.501 8.501 0 1 0 8.502-14.725 8.485 8.485 0 0 0-2.792-1.016c.647 3.384.23 6.044-1.045 8.476Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
                <div
                  className="relative flex h-5 w-7 flex-row items-center justify-center rounded-lg transition hover:text-primary bg-primary text-surface-lowest hover:bg-primary-darker hover:text-surface-lowest"
                  title="System theme"
                >
                  <input
                    name="theme"
                    type="radio"
                    aria-label="System theme"
                    className="z-1 absolute m-0 h-full w-full cursor-pointer appearance-none p-0 opacity-0"
                  />
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className=" Icon "
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-width="2"
                    stroke="currentColor"
                    stroke-linecap="round"
                  >
                    <g stroke-width="1.5">
                      <path d="M0 0h24v24H0z" stroke="none"></path>
                      <path d="M3 5a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5zM7 20h10M9 16v4M15 16v4"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="separator"></div>
            <span
              className="item red"
              role="menuitem"
              tabIndex={-1}
              onClick={onSignOut}
            >
              <SignOutIcon />
              Sign-out
            </span>

            <div className="flex justify-center">
              <span
                className="block truncate text-[10px] text-gray-50"
                title={authUser?.email}
              >
                {authUser?.email}
              </span>
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default function SidebarFooter() {
  const { navigate } = useContext(RouterContext);

  return (
    <div className="relative flex justify-between gap-2 border-t border-surface-high px-3 py-4">
      <ProfileInfo />
      <button
        type="button"
        aria-haspopup="dialog"
        aria-expanded="false"
        aria-controls="radix-:r44:"
        data-state="closed"
        className="hover:bg-transparent active:bg-transparent"
        onClick={() => {
          navigate("/w/3rzG1yGrEM/notifications");
        }}
      >
        <div className="undefined relative flex cursor-pointer items-center justify-center rounded-md border-[1px] border-solid border-surface-high bg-transparent p-2 text-xl text-on-surface hover:!bg-surface-high active:!bg-surface-high">
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className="pointer-events-none Icon "
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 1.996a7.49 7.49 0 0 1 7.496 7.25l.004.25v4.097l1.38 3.156a1.25 1.25 0 0 1-1.145 1.75L15 18.502a3 3 0 0 1-5.995.177L9 18.499H4.275a1.251 1.251 0 0 1-1.147-1.747L4.5 13.594V9.496c0-4.155 3.352-7.5 7.5-7.5ZM13.5 18.5l-3 .002a1.5 1.5 0 0 0 2.993.145l.006-.147ZM12 3.496c-3.32 0-6 2.674-6 6v4.41L4.656 17h14.697L18 13.907V9.509l-.004-.225A5.988 5.988 0 0 0 12 3.496Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </button>
    </div>
  );
}
