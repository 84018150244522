import { getPrivateCollaborationLinkData } from ".";
import {
  isSavedToFirebase,
  loadFilesFromFirebase,
  loadFromFirebase,
  saveFilesToFirebase,
  saveToFirebase,
} from "./firebase";
import {
  isSavedToHttpStorage,
  loadFilesFromHttpStorage,
  loadFromHttpStorage,
  saveFilesToHttpStorage,
  saveToHttpStorage,
} from "./httpStorage";
import type { IStorageBackend } from "./StorageBackend";

const firebaseStorage: IStorageBackend = {
  isSaved: isSavedToFirebase,
  saveToStorage: saveToFirebase,
  loadFromStorage: loadFromFirebase,
  saveFilesToStorage: saveFilesToFirebase,
  loadFilesFromStorage: loadFilesFromFirebase,
};

const httpStorage: IStorageBackend = {
  isSaved: isSavedToHttpStorage,
  saveToStorage: saveToHttpStorage,
  loadFromStorage: loadFromHttpStorage,
  saveFilesToStorage: saveFilesToHttpStorage,
  loadFilesFromStorage: loadFilesFromHttpStorage,
};

// const storageBackends = new Map<string, IStorageBackend>([
//   ["firebase", firebaseStorage],
//   ["http", httpStorage],
// ]);

function getStorageBackend() {
  const privateMode = getPrivateCollaborationLinkData();
  if (privateMode) {
    return httpStorage;
  }
  return firebaseStorage;
}
export { getStorageBackend };
