import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { workspaceService } from "../../share/services";
import { useAtom, useAtomValue } from "jotai";
import { toastAtom, workspaceAtom } from "../../app-jotai";
import useRefreshWorkspace from "../../share/hook/useRefreshWorkspace";

export function CreateWorkspaceModal({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const workspace = useAtomValue(workspaceAtom);
  const [toast, setToast] = useAtom(toastAtom);
  const [value, setValue] = useState("");
  const refreshWorkspace = useRefreshWorkspace();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);
  const onSubmit = async () => {
    if (!value) {
      setToast({
        ...toast,
        isShow: true,
        message: "Please enter a workspace name",
      });
    }
    await workspaceService.create({
      name: value,
      workspaceId: workspace?.id || "",
    });
    await refreshWorkspace();
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {/* <button
        type="button"
        className="h-6 w-6 rounded flex items-center justify-center bg-primary hover:bg-brand-hover active:bg-brand-active disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest text-base active:scale-[.98]"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          className=" Icon "
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z"
            fill="currentColor"
          ></path>
        </svg>
      </button> */}

      <Dialog.Portal>
        <div
          className="ModalDialog scrollbar-sm z-20"
          role="dialog"
          aria-modal="true"
          aria-labelledby="Create Workspace"
        >
          <Dialog.Overlay className="ModalDialog-background" />
          <Dialog.Content
            className="ModalDialog-content x-custom-scrollbar bg-white dark:bg-surface-low"
            style={{
              maxWidth: "450px",
            }}
          >
            <Dialog.Title className="ModalDialog-title mb-2 max-w-full text-ellipsis">
              <span className="text-lg font-bold text-color-text sm:text-xl">
                Create Workspace
              </span>
            </Dialog.Title>
            <form
              className="FormComponent-form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="FormComponent-row gap-x-6">
                <div className="FormComponent-item ">
                  <label htmlFor="ias" className="font-bold">
                    Workspace name
                  </label>
                  <input
                    className="x-text-size-inherit h-full border-0 shadow-sm"
                    type="text"
                    autoComplete="off"
                    id="ias"
                    value={value}
                    onChange={onChange}
                  />
                  <p className="FormComponent-error-message">&nbsp;</p>
                </div>
              </div>
              <div className="FormComponent-row mt-3 justify-end">
                <Dialog.Close asChild>
                  <button
                    type="button"
                    className="flex items-center justify-center border-[1px] border-transparent bg-transparent hover:!bg-surface-high active:!bg-surface-high disabled:text-border-outline-variant disabled:hover:!bg-transparent text-primary hover:text-primary-darker rounded-md px-6 py-2.5 text-base font-bold media-xs:order-2 active:scale-[.98]"
                  >
                    Cancel
                  </button>
                </Dialog.Close>
                <button
                  type="button"
                  className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
                  onClick={onSubmit}
                >
                  <div className="relative">
                    <div className="">Create workspace</div>
                  </div>
                </button>
              </div>
            </form>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
