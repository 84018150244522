import { useAtomValue } from "jotai";
import {
  collabAPIAtom,
  isCallingAtom,
  numberParticipantAtom,
} from "../collab/Collab";
import { useState } from "react";
import {
  MicroIcon,
  MuteIcon,
} from "../../packages/excalidraw/components/icons";

export default function CallTray() {
  const isCalling = useAtomValue(isCallingAtom);
  const collabAPI = useAtomValue(collabAPIAtom);
  const numberParticipant = useAtomValue(numberParticipantAtom);
  const [mute, setMute] = useState(false);
  return isCalling ? (
    <div className="call-tray-wrapper !pointer-events-none absolute right-0 top-10 mt-3 flex h-10 w-full justify-end 3xl:h-full">
      <div
        className="pointer-events-auto relative flex items-center gap-1 rounded-xl border border-green-based bg-surface-lowest p-1"
        style={{
          boxShadow: "var(--shadow-island)",
        }}
      >
        <div>
          <div className="w-fit" data-state="closed">
            <button
              type="button"
              className="excalidraw-button !border-0 shadow-[0_0_0_1px_var(--box-shadow-normal)] active:shadow-[--box-shadow-active] !h-8 !w-8 !bg-surface-lowest"
              style={
                {
                  "--button-bg": "var(--color-surface-low)",
                  "--button-width": "var(--lg-button-size)",
                  "--button-height": "var(--lg-button-size)",
                  "--box-shadow-normal": "var(--color-surface-lowest)",
                  "--box-shadow-active": "var(--color-brand-active)",
                } as React.CSSProperties
              }
              onClick={() => setMute(!mute)}
            >
              {mute ? <MuteIcon /> : <MicroIcon />}
            </button>
          </div>
        </div>
        <div className="h-5 w-px bg-surface-high"></div>
        <div className="w-fit" data-state="closed">
          <button
            type="button"
            className="excalidraw-button !border-0 shadow-[0_0_0_1px_var(--box-shadow-normal)] active:shadow-[--box-shadow-active] !h-8 !w-auto gap-x-2 !bg-red-light text-xs font-semibold !text-red-highest hover:!bg-red-based disabled:!cursor-not-allowed disabled:!opacity-50"
            style={
              {
                "--button-bg": "var(--color-surface-low)",
                "--button-width": "var(--lg-button-size)",
                "--button-height": "var(--lg-button-size)",
                "--box-shadow-normal": "var(--color-surface-lowest)",
                "--box-shadow-active": "var(--color-brand-active)",
              } as React.CSSProperties
            }
            onClick={() => collabAPI?.leaveCall()}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className=" Icon "
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              stroke="currentColor"
              strokeLinecap="round"
              style={{
                width: "var(--lg-icon-size)",
                height: "var(--lg-icon-size)",
                flex: "0 0 auto",
              }}
            >
              <g
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M3 21l18 -18"></path>
                <path d="M5.831 14.161a15.946 15.946 0 0 1 -2.831 -8.161a2 2 0 0 1 2 -2h4l2 5l-2.5 1.5c.108 .22 .223 .435 .345 .645m1.751 2.277c.843 .84 1.822 1.544 2.904 2.078l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a15.963 15.963 0 0 1 -10.344 -4.657"></path>
              </g>
            </svg>
          </button>
        </div>
        <span className="pointer-events-none absolute -bottom-2 -left-2 flex h-4 w-4 items-center justify-center rounded-full bg-green-based text-xs text-green-highest">
          {numberParticipant}
        </span>
      </div>
    </div>
  ) : (
    <></>
  );
}
