/* eslint-disable prettier/prettier */
import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { AxiosError } from "axios";
import axios from "axios";

export interface BaseResponse<T> {
  status: number;
  data: T | null;
  error: AxiosError | null;
}

export class AbstractApiService {
  _axiosApi: AxiosInstance;

  constructor(axiosInstance?: AxiosInstance) {
    if (!axiosInstance) {
      const axiosApi = axios.create({
        baseURL: import.meta.env.VITE_HTTP_STORAGE_BACKEND_URL,
        timeout: 5000,
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      axiosApi.interceptors.request.use((config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      });
      axiosApi.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          const originalRequest = error.config;
          if (
            (!error.response || error.response.status === 403) &&
            !originalRequest._retry
          ) {
            // originalRequest._retry = true;
            // const access_token = await refreshToken();
            // axios.defaults.headers.common['Authorization'] =
            //    'Bearer ' + access_token;
            // return axiosApi(originalRequest);
          }
          return Promise.reject(error);
        },
      );
      axiosInstance = axiosApi;
    }
    this._axiosApi = axiosInstance;
  }

  protected async postApi<TRequest, TResponse>(
    path: string,
    payload?: TRequest,
    config?: AxiosRequestConfig,
  ): Promise<BaseResponse<TResponse>> {
    try {
      const response = await this._axiosApi.post<TResponse>(
        path,
        payload,
        config,
      );
      return { status: response.status, data: response.data, error: null };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        status: axiosError?.response?.status || axiosError?.status || 500,
        data: null,
        error: error as AxiosError,
      };
    }
  }

  protected async patchApi<TRequest, TResponse>(
    path: string,
    payload: TRequest,

    config?: AxiosRequestConfig,
  ): Promise<BaseResponse<TResponse>> {
    try {
      const response = await this._axiosApi.patch<TResponse>(
        path,
        payload,
        config,
      );
      return { status: response.status, data: response.data, error: null };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        status: axiosError?.response?.status || axiosError?.status || 500,
        data: null,
        error: error as AxiosError,
      };
    }
  }

  protected async putApi<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: AxiosRequestConfig,
  ): Promise<BaseResponse<TResponse>> {
    try {
      const response = await this._axiosApi.put<TResponse>(
        path,
        payload,
        config,
      );
      return { status: response.status, data: response.data, error: null };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        status: axiosError?.response?.status || axiosError?.status || 500,
        data: null,
        error: error as AxiosError,
      };
    }
  }

  protected async deleteApi<TResponse>(
    path: string,
  ): Promise<BaseResponse<TResponse>> {
    try {
      const response = await this._axiosApi.delete<TResponse>(path);
      return { status: response.status, data: response.data, error: null };
    } catch (error: any) {
      const axiosError = error as AxiosError;
      return {
        status: axiosError?.response?.status || axiosError?.status || 500,
        data: null,
        error: error as AxiosError,
      };
    }
  }

  protected async getApi<TResponse>(
    path: string,
  ): Promise<BaseResponse<TResponse>> {
    try {
      const response = await this._axiosApi.get<TResponse>(path);
      return { status: response.status, data: response.data, error: null };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        status: axiosError?.response?.status || axiosError?.status || 500,
        data: null,
        error: error as AxiosError,
      };
    }
  }

  // async function uploadFile<TResponse>(
  //    url: string,
  //    uploadInfo: UploadFile,
  //    method?: string
  // ) {
  //    const options = {
  //       headers: {
  //          'Content-Type': 'multipart/form-data',
  //       },
  //    };

  //    const formData = makeUploadFormData(uploadInfo);
  //    let response: Promise<BaseResponse<TResponse>>;

  //    if (method === 'put')
  //       response = putApi<any, TResponse>(url, formData, options);
  //    else if (method === 'patch')
  //       response = patchApi<any, TResponse>(url, formData, options);
  //    else response = postApi<any, TResponse>(url, formData, options);
  //    return response;
  // }

  // export async function postUploadFile<TResponse>(
  //    url: string,
  //    uploadInfo: UploadFile
  // ) {
  //    return uploadFile<TResponse>(url, uploadInfo);
  // }

  // export async function putUploadFile<TResponse>(
  //    url: string,
  //    uploadInfo: UploadFile
  // ) {
  //    return uploadFile<TResponse>(url, uploadInfo, 'put');
  // }

  // export async function patchUploadFile<TResponse>(
  //    url: string,
  //    uploadInfo: UploadFile
  // ) {
  //    return uploadFile<TResponse>(url, uploadInfo, 'patch');
  // }

  // export async function postUploadFiles<TResponse>(
  //    url: string,
  //    uploadInfos: UploadFile[]
  // ) {
  //    return uploadFiles<TResponse>(url, uploadInfos);
  // }

  // export async function putUploadFiles<TResponse>(
  //    url: string,
  //    uploadInfos: UploadFile[]
  // ) {
  //    return uploadFiles<TResponse>(url, uploadInfos, 'put');
  // }

  // export async function patchUploadFiles<TResponse>(
  //    url: string,
  //    uploadInfos: UploadFile[]
  // ) {
  //    return uploadFiles<TResponse>(url, uploadInfos, 'patch');
  // }

  // async function uploadFiles<TResponse>(
  //    url: string,
  //    uploadInfos: UploadFile[],
  //    method?: string
  // ) {
  //    const options = {
  //       headers: {
  //          'Content-Type': 'multipart/form-data',
  //       },
  //    };

  //    const formData = makeUploadInfosFormData(uploadInfos);
  //    let response: Promise<BaseResponse<TResponse>>;

  //    if (method === 'put')
  //       response = putApi<any, TResponse>(url, formData, options);
  //    else if (method === 'patch')
  //       response = patchApi<any, TResponse>(url, formData, options);
  //    else response = postApi<any, TResponse>(url, formData, options);
  //    return response;
  // }

  // function makeUploadFormData(uploadInfo: UploadFile) {
  //    let formData = new FormData();
  //    if (uploadInfo.isMultple) {
  //       for (let i = 0; i < uploadInfo.files.length; i++) {
  //          formData.append(`${uploadInfo.name}[${i}]`, uploadInfo.files[i]);
  //       }
  //    } else {
  //       formData.append(uploadInfo.name, uploadInfo.files[0]);
  //    }
  //    return formData;
  // }

  // function makeUploadInfosFormData(uploadInfos: UploadFile[]) {
  //    let formData = new FormData();
  //    for (let i = 0; i < uploadInfos.length; i++) {
  //       const uploadInfo = uploadInfos[i];
  //       if (uploadInfo.isMultple) {
  //          for (let j = 0; j < uploadInfo.files.length; j++) {
  //             formData.append(uploadInfo.name, uploadInfo.files[j]);
  //          }
  //       } else {
  //          formData.append(uploadInfo.name, uploadInfo.files[0]);
  //       }
  //    }

  //    return formData;
  // }

  // export function makeFormData(details) {
  //    let formBody: any[] = [];
  //    for (let property in details) {
  //       let encodedKey = encodeURIComponent(property);
  //       let encodedValue = encodeURIComponent(details[property]);
  //       formBody.push(encodedKey + '=' + encodedValue);
  //    }
  //    return formBody.join('&');
  // }
}
