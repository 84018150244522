import "./WorkspaceSetting.scss";

export default function WorkspaceSetting() {
  return (
    <main className="relative box-border px-4 pb-14 pt-28 sm:px-12">
      <a className="x-reset-link flex" href="/o/3rzG1yGrEM">
        <button
          type="button"
          className="h-6 w-6 rounded flex items-center justify-center bg-primary hover:bg-brand-hover active:bg-brand-active disabled:bg-surface-low disabled:text-border-outline-variant text-md text-surface-lowest absolute right-4 top-4 select-none !rounded-full sm:right-8 sm:top-8 active:scale-[.98]"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className=" Icon "
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="m4.397 4.554.073-.084a.75.75 0 0 1 .976-.073l.084.073L12 10.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L13.061 12l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L12 13.061l-6.47 6.47a.75.75 0 0 1-1.06-1.061L10.939 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084-.073.084Z"
              fill="currentColor"
            ></path>
          </svg>
          <div className="shortcut absolute left-0 top-8 w-full text-xs text-gray-60">
            esc
          </div>
        </button>
      </a>
      <div className="SettingsContainer-content-body">
        <div className="SettingsProfile">
          <div className="SettingsProfile-avatar">
            <img
              className="pointer-events-none !select-none rounded-full object-contain hover:brightness-95 active:brightness-75 "
              src="https://app.excalidraw.com//letter-avatars/t_indigo.png"
              alt="test"
              referrerPolicy="no-referrer"
              style={{ width: "150px", height: "150px" }}
            />
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="upload-icon Icon "
              viewBox="0 0 24 24"
              fill="none"
              stroke-width="2"
              stroke="currentColor"
              stroke-linecap="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
            <div className="absolute bottom-1 right-4">
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                className="!static !transform-none Icon "
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.5 12a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm0 2-.09.007a.5.5 0 0 0-.402.402L17 14.5V17h-2.502l-.09.009a.5.5 0 0 0-.402.402l-.008.09.008.09a.5.5 0 0 0 .402.402l.09.008H17v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008.09-.008a.5.5 0 0 0 .402-.402l.008-.09V18h2.504l.09-.007a.5.5 0 0 0 .402-.402l.008-.09-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H18v-2.5l-.008-.09a.5.5 0 0 0-.402-.403L17.5 14ZM13.925 2.502a2.25 2.25 0 0 1 1.94 1.11L16.679 5h2.071A3.25 3.25 0 0 1 22 8.25v4.56a6.517 6.517 0 0 0-1.499-1.077L20.5 8.25a1.75 1.75 0 0 0-1.75-1.75h-2.5a.75.75 0 0 1-.647-.37l-1.032-1.757a.75.75 0 0 0-.646-.37h-3.803a.75.75 0 0 0-.574.268l-.065.09L8.39 6.141a.75.75 0 0 1-.639.358h-2.5A1.75 1.75 0 0 0 3.5 8.25v9.5c0 .966.784 1.75 1.75 1.75h6.063c.173.533.412 1.037.709 1.5H5.25A3.25 3.25 0 0 1 2 17.75v-9.5A3.25 3.25 0 0 1 5.25 5h2.08l.875-1.424a2.25 2.25 0 0 1 1.917-1.073h3.803ZM12 8a4.502 4.502 0 0 1 4.283 3.114c-.5.095-.98.247-1.433.449A2.999 2.999 0 0 0 9 12.5c0 1.43 1 2.625 2.338 2.927a6.446 6.446 0 0 0-.31 1.468A4.501 4.501 0 0 1 12 8Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>
          <h1 className="my-3 break-all text-3xl font-bold">test</h1>
          <button
            type="button"
            className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] mt-4 bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="!h-8 !w-8 px-2 py-1 Icon "
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.03 2.97a3.578 3.578 0 0 1 0 5.06L9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L15.97 2.97a3.578 3.578 0 0 1 5.06 0ZM15 6.06 5.062 16a.75.75 0 0 0-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 0 0 8 18.938L17.94 9 15 6.06Zm2.03-2.03-.97.97L19 7.94l.97-.97a2.079 2.079 0 0 0-2.94-2.94Z"
                fill="currentColor"
              ></path>
            </svg>
            <span className="py-1 pr-2">Rename workspace</span>
          </button>
        </div>
      </div>
    </main>
  );
}
