import type { BaseResponse } from "../http/base";
import { AbstractApiService } from "../http/base";
import type { Permisison } from "../model/Permisison";

class PublicService extends AbstractApiService {
  async getScencePermissions(
    sceneId: string,
  ): Promise<BaseResponse<Permisison>> {
    return this.getApi(`/public/scenes/${sceneId}/permissions`);
  }
  async shareLink(
    sceneId: string,
    payload: {
      value?: number;
      active: boolean;
    },
  ): Promise<BaseResponse<Permisison>> {
    return this.putApi(`/public/scenes/${sceneId}/share`, payload);
  }
}

export const publicService = new PublicService();
