import { useAtom } from "jotai";
import { sidebarSelectedItemAtom } from "../../app-jotai";

export function useSelectedItem() {
  const [slectedItem, setSelectedItem] = useAtom(sidebarSelectedItemAtom);

  const isSelected = (key: string) => {
    return slectedItem === key;
  };

  return { isSelected, setSelectedItem };
}
