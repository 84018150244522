import { useAtomValue } from "jotai";
import {
  CreateSceneIcon,
  ImportIcon,
} from "../../../../packages/excalidraw/components/icons";
import { sidebarSelectedItemAtom, workspaceIdAtom } from "../../../app-jotai";
import useCreateScence from "../../../share/hook/useCreateScence";

const SmallPlusIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    className=" Icon "
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z"
      fill="currentColor"
    ></path>
  </svg>
);

export function WorkSpaceToolBar() {
  const workspaceId = useAtomValue(workspaceIdAtom);
  const collectionId = useAtomValue(sidebarSelectedItemAtom);
  const onCreateSence = useCreateScence();
  return (
    <div className="my-6 flex flex-col items-center justify-center gap-x-6 gap-y-3 xs:flex-row xs:items-start xs:justify-start">
      <div className="contents">
        <button
          type="button"
          className="group select-none flex flex-row items-center justify-between bg-surface-lowest active:scale-[.98] rounded-md px-4 py-2 w-full max-w-[350px] sm:max-w-[270px] border-[1px] border-solid border-surface-high hover:border-brand-hover hover:bg-surface-lowest active:border-brand-active active:bg-surface-lowest"
        >
          <div className="flex">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-surface-primary-container text-base text-primary sm:h-12 sm:w-12 sm:text-2xl">
              <ImportIcon className="h-5 w-5" />
            </div>
            <div className="ml-3 flex items-center justify-center text-base text-color-text group-active:text-primary-darkest">
              Import scenes
            </div>
          </div>
          <div className="flex h-full items-center justify-center text-2xl text-primary group-active:text-primary-darkest">
            <SmallPlusIcon />
          </div>
        </button>
        <button
          type="button"
          className="group select-none flex flex-row items-center justify-between bg-surface-lowest active:scale-[.98] rounded-md px-4 py-2 w-full max-w-[350px] sm:max-w-[270px] border-[1px] border-solid border-surface-high hover:border-brand-hover hover:bg-surface-lowest active:border-brand-active active:bg-surface-lowest"
          onClick={() =>
            onCreateSence({
              collectionId,
              value: [],
              workspaceId: workspaceId || null,
            })
          }
        >
          <div className="flex">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-surface-primary-container text-base text-primary sm:h-12 sm:w-12 sm:text-2xl">
              <CreateSceneIcon className="h-5 w-5" />
            </div>
            <div className="ml-3 flex items-center justify-center text-base text-color-text group-active:text-primary-darkest">
              Create scene
            </div>
          </div>
          <div className="flex h-full items-center justify-center text-2xl text-primary group-active:text-primary-darkest">
            <SmallPlusIcon />
          </div>
        </button>
      </div>
    </div>
  );
}
