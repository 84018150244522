import type { IWorkspace } from "../model";
import type { BaseResponse } from "../http/base";
import { AbstractApiService } from "../http/base";
import type { CreateWorkspaceDto } from "./types";
import { createQueryParams } from "../utils";

class WorkspaceService extends AbstractApiService {
  async create(payload: CreateWorkspaceDto): Promise<BaseResponse<IWorkspace>> {
    return this.postApi<CreateWorkspaceDto, IWorkspace>("/workspaces", payload);
  }

  async getUserWorkspace({
    workspaceId,
    collectionId,
    sceneId,
  }: {
    workspaceId?: string;
    collectionId?: string;
    sceneId?: string;
  }): Promise<BaseResponse<IWorkspace>> {
    const querries = createQueryParams({
      collectionId,
      sceneId,
    });
    const defautURl = `workspaces/default${querries}`;
    if (collectionId || sceneId) {
      return this.getApi<IWorkspace>(defautURl);
    }
    if (workspaceId) {
      return this.getApi<IWorkspace>(`workspaces/${workspaceId}`);
    }

    return this.getApi<IWorkspace>(defautURl);
  }
}
export const workspaceService = new WorkspaceService();
