import { useAtomValue } from "jotai";
import type { PermissionChangePayload } from "../../collab/Collab";
import { collabAPIAtom } from "../../collab/Collab";
import { authUserAtom, sceneIdAtom } from "../../app-jotai";
import { publicService } from "../services/public.service";

export default function useChangePermission() {
  const collabAPI = useAtomValue(collabAPIAtom);
  const authUser = useAtomValue(authUserAtom);
  const sceneId = useAtomValue(sceneIdAtom);

  return async (payload: Partial<PermissionChangePayload>) => {
    const result = await publicService.shareLink(
      sceneId || "",
      payload as {
        value?: number;
        active: boolean;
      },
    );
    if (authUser && collabAPI) {
      collabAPI.changeScenePermission(sceneId || "null", {
        ...payload,
        ownerId: authUser.userId,
      } as PermissionChangePayload);
    }
    return result;
  };
}
