import { useEffect, useState } from "react";
import { VERSIONS } from "../constants";
import { t } from "../i18n";
import type { ExcalidrawProps, UIAppState } from "../types";

const LibraryMenuBrowseButton = ({
  theme,
  id,
  libraryReturnUrl,
}: {
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  theme: UIAppState["theme"];
  id: string;
}) => {
  const referrer =
    libraryReturnUrl || window.location.origin + window.location.pathname;

  const [libraryUrl, setLibraryUrl] = useState("");
  const getLibraryUrl = async () => {
    const LIBRARY_URL = import.meta.env.VITE_APP_LIBRARY_URL;
    setLibraryUrl(LIBRARY_URL);
  };
  useEffect(() => {
    if (!libraryUrl) {
      getLibraryUrl();
    }
  });

  return (
    <a
      className="library-menu-browse-button"
      href={`${libraryUrl}?target=${
        window.name || "_blank"
      }&referrer=${referrer}&useHash=true&token=${id}&theme=${theme}&version=${
        VERSIONS.excalidrawLibrary
      }`}
      target="_excalidraw_libraries"
    >
      {t("labels.libraries")}
    </a>
  );
};

export default LibraryMenuBrowseButton;
