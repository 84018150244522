import type { Collaborator, SocketId } from "../../packages/excalidraw/types";
import { appJotaiStore } from "../app-jotai";
import { isInCallAtom, type TCollabClass } from "./Collab";
import type { MediaConnection } from "peerjs";
import { Peer } from "peerjs";
declare global {
  interface Window {
    localStream: MediaStream;
  }
}

declare const window: Window & typeof globalThis;

class Streaming {
  collab: TCollabClass;
  peer: Peer | null = null;
  peerInitialized: boolean = false;
  participants: Map<string, MediaConnection> = new Map();
  constructor(collab: TCollabClass) {
    this.collab = collab;
  }

  async open() {
    if (this.peer || !this.collab.portal.socket) {
      return;
    }
    await this.getLocalStream();
    try {
      this.peer = new Peer({
        config: {
          iceServers: [
            {
              urls: "turn:103.82.21.202:3478",
              username: "whiteboard",
              credential: "password",
            },
          ],
        },
      });
      this.peer.on("open", (id) => {
        this.peerInitialized = true;
        this.collab.portal.socket?.emit("join-call", {
          roomId: this.collab.portal.roomId,
          peerId: id,
        });
        this.collab.setIsCalling(true);
      });

      this.peer.on("call", async (call) => {
        call.answer(window.localStream);

        call.on("stream", (stream) => {
          const peerId = call.peer;
          let audio = document.getElementById(peerId) as HTMLAudioElement;
          if (!audio) {
            audio = new Audio();
            audio.id = peerId;
            document.body.appendChild(audio);
          }
          audio.srcObject = stream;
          audio.autoplay = true;
        });

        call.on("close", () => {
          const peerId = call.peer;
          this.deleteParticipant(peerId);
        });
      });
    } catch (error) {
      console.error("Error creating peer", error);
    }
  }

  handleCallRoomChange(
    participants: ({
      socketId: SocketId;
      peerId: string;
    } & Partial<Collaborator>)[],
  ) {
    participants.forEach((participant) => {
      if (participant?.peerId === this.peer?.id) {
        return;
      }
      if (!this.peer || this.participants.has(participant?.peerId)) {
        return;
      }
      const call = this.peer.call(participant.peerId, window.localStream);
      this.setParticipants(participant.peerId, call);
    });

    const isInCall = participants.some((clb) => clb.peerId);
    if (isInCall !== appJotaiStore.get(isInCallAtom)) {
      this.collab.setIsInCall(isInCall);
    }
  }

  private setParticipants(peerId: string, call: MediaConnection) {
    this.participants.set(peerId, call);
  }

  private deleteParticipant(peerId: string) {
    this.participants.delete(peerId);
    const audio = document.getElementById(peerId) as HTMLAudioElement;
    if (audio) {
      audio.remove();
    }
  }

  private async getLocalStream() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      window.localStream = stream;
    } catch (e) {
      console.error("Error getting local stream", e);
    }
  }

  private cleanStream() {
    window.localStream.getAudioTracks().forEach((track) => {
      track.stop();
    });
  }

  close() {
    if (this.peer) {
      this.collab.portal.socket?.emit("leave-call", this.collab.portal.roomId);
      this.cleanStream();
      for (const [peerId, call] of this.participants.entries()) {
        call.close();
        if (peerId !== this.peer?.id) {
          this.deleteParticipant(peerId);
        }
      }
      this.collab.setIsCalling(false);
      this.peer.destroy();
      this.peer = null;
    }
  }

  isOpen() {
    return !!(this.peerInitialized && this.peer);
  }
}

export default Streaming;
