import clsx from "clsx";
import "./SignIn.scss";
import useLogin from "../../share/hook/useLogin";

export default function SignIn() {
  const login = useLogin();
  return (
    <div className={clsx("signin-wrapper")}>
      <button
        onClick={() => {
          login();
        }}
        style={{}}
      >
        Sign in with Google
      </button>
    </div>
  );
}
