export default function MemberSetting() {
  return (
    <main className="relative box-border px-4 pb-14 pt-28 sm:px-12">
      <a className="x-reset-link flex" href="/o/3rzG1yGrEM">
        <button
          type="button"
          className="h-6 w-6 rounded flex items-center justify-center bg-primary hover:bg-brand-hover active:bg-brand-active disabled:bg-surface-low disabled:text-border-outline-variant text-md text-surface-lowest absolute right-4 top-4 select-none !rounded-full sm:right-8 sm:top-8 active:scale-[.98]"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className=" Icon "
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="m4.397 4.554.073-.084a.75.75 0 0 1 .976-.073l.084.073L12 10.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L13.061 12l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L12 13.061l-6.47 6.47a.75.75 0 0 1-1.06-1.061L10.939 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084-.073.084Z"
              fill="currentColor"
            ></path>
          </svg>
          <div className="shortcut absolute left-0 top-8 w-full text-xs text-gray-60">
            esc
          </div>
        </button>
      </a>
      <ul className="mx-0 my-4 list-none rounded-lg border border-solid border-gray-10 px-0 dark:border-gray-20">
        <li className="border-0 border-b border-solid border-gray-10 p-4 text-sm dark:border-gray-20">
          <div className="grid grid-cols-2 xs:grid-cols-4">
            <div className="flex flex-col xs:col-span-3">
              <button
                type="button"
                className="flex items-center justify-center px-8 py-2.5 font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] w-full text-sm xs:hidden bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className="mr-2 Icon "
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke-width="2"
                  stroke="currentColor"
                  stroke-linecap="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 15l6 -6"></path>
                  <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path>
                  <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"></path>
                </svg>
                Copy link
              </button>
              <div className="hidden xs:block">
                <div className="mb-1 flex justify-between">
                  <div className="text-sm font-semibold">Link</div>
                </div>
                <div className="relative flex">
                  <div className="flex h-12 w-full cursor-pointer items-center rounded-lg bg-surface-high py-0 pl-4 text-base pr-12">
                    <div className="truncate">
                      https://link.excalidraw.com/redeem/3rzG1yGrEM/1sSTPGJUqvO
                    </div>
                  </div>
                  <div className="absolute bottom-0 right-0 top-0 mr-4 flex items-center">
                    <div className="w-fit" data-state="closed">
                      <button
                        type="button"
                        className="flex items-center justify-center p-2 rounded cursor-pointer text-xl text-on-surface bg-transparent disabled:text-border-outline-variant disabled:hover:!bg-transparent hover:!bg-primary-light active:!bg-primary-light"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          role="img"
                          className=" Icon "
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke-width="2"
                          stroke="currentColor"
                          stroke-linecap="round"
                        >
                          <rect
                            x="9"
                            y="9"
                            width="13"
                            height="13"
                            rx="2"
                            ry="2"
                          ></rect>
                          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 ml-auto">
              <span className="inline-flex flex-shrink-0 items-center rounded-full px-2 py-0.5 text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200">
                member
              </span>
            </div>
          </div>
          <div className="mt-2 grid grid-cols-2">
            <div className="flex items-center">
              <div className="w-fit" data-state="closed">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className="mr-1 cursor-pointer Icon "
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke-width="2"
                  stroke="currentColor"
                  stroke-linecap="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
              </div>
              <span>Uses:</span>
              <span className="ml-1">0/1</span>
            </div>
            <div className="ml-auto flex gap-x-4">
              <button
                type="button"
                className="flex items-center justify-center p-2 rounded cursor-pointer text-on-surface bg-transparent hover:!bg-surface-high active:!bg-surface-high disabled:text-border-outline-variant disabled:hover:!bg-transparent text-sm font-medium"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className="h-5 w-5 Icon "
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.03 2.97a3.578 3.578 0 0 1 0 5.06L9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L15.97 2.97a3.578 3.578 0 0 1 5.06 0ZM15 6.06 5.062 16a.75.75 0 0 0-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 0 0 8 18.938L17.94 9 15 6.06Zm2.03-2.03-.97.97L19 7.94l.97-.97a2.079 2.079 0 0 0-2.94-2.94Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
              <button
                type="button"
                className="flex items-center justify-center p-2 rounded cursor-pointer text-on-surface bg-transparent hover:!bg-surface-high active:!bg-surface-high disabled:text-border-outline-variant disabled:hover:!bg-transparent text-sm font-medium"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className="h-5 w-5 Icon "
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75Zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5ZM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75Zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75Zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div className="SettingsContainer-content-body">
        <div className="flex flex-col gap-y-4 sm:flex-row sm:gap-x-4 sm:gap-y-0">
          <button
            type="button"
            className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] !shadow-none bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="mr-3 Icon "
              viewBox="0 0 24 24"
              fill="none"
              stroke-width="2"
              stroke="currentColor"
              stroke-linecap="round"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
            Invite user
          </button>
          <button
            type="button"
            className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] !shadow-none bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="mr-3 Icon "
              viewBox="0 0 24 24"
              fill="none"
              stroke-width="2"
              stroke="currentColor"
              stroke-linecap="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M9 15l6 -6"></path>
              <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path>
              <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"></path>
            </svg>
            Create invite link
          </button>
        </div>

        <div className="UserList !max-w-none md-lg:pr-24 xl:pr-0">
          <h2 className="mb-3 mt-16 text-xl font-bold">Members (1)</h2>
          <div className="text-sm">
            {" "}
            You will be billed for each member at the end of your billing cycle.
            See{" "}
            <a className="x-link-like" href="/o/3rzG1yGrEM/settings/invoices">
              invoices
            </a>{" "}
            for details.
          </div>

          <div className="my-8">
            <ul className="my-6 grid list-none gap-6 p-0 sm:grid-cols-2 sm:gap-4 md:gap-6 md-lg:grid-cols-1 xl:grid-cols-2">
              <li className="relative col-span-1 flex min-w-0 flex-col rounded-lg border border-solid border-gray-10 shadow dark:border-gray-20">
                <div className="flex-1 border-0 border-b border-solid border-gray-10 dark:border-gray-20">
                  <div className="box-border flex w-full items-start justify-between space-x-6 p-4 text-sm sm:space-x-4 group xs:p-6 sm:space-x-4 sm:p-4 md:space-x-6 md:p-6">
                    <img
                      className="h-8 w-8 flex-shrink-0 rounded-full xs:h-10 xs:w-10 sm:h-8 sm:w-8 md:h-10 md:w-10"
                      src="https://avatars.githubusercontent.com/u/49265862?v=4"
                      alt="dungbui.dungbui.00"
                      referrerPolicy="no-referrer"
                    />
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <div className="truncate text-sm font-medium text-color-text">
                          dungbui.dungbui.00
                        </div>
                        <span className="inline-flex flex-shrink-0 items-center rounded-full px-2 py-0.5 text-xs font-medium bg-green-200 text-green-800 dark:bg-green-700 dark:text-green-100">
                          admin
                        </span>
                      </div>

                      <div className="mt-1">
                        <div className="relative flex items-center">
                          <div className="email truncate text-sm text-gray-60">
                            dungbui.dungbui.00@gmail.com{" "}
                          </div>
                          <button className="h-6 w-6 absolute right-0 z-40 rounded-full bg-default text-color-text hover:bg-surface-high hidden items-center justify-center group-hover:flex">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              role="img"
                              className="p-0.5 Icon "
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke-width="2"
                              stroke="currentColor"
                              stroke-linecap="round"
                            >
                              <rect
                                x="9"
                                y="9"
                                width="13"
                                height="13"
                                rx="2"
                                ry="2"
                              ></rect>
                              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div className="-mt-px flex">
                      <div className="flex w-0 flex-1 border-0 border-r border-solid border-gray-10 dark:border-gray-20">
                        <button className="relative bg-transparent text-sm font-medium text-gray-60 inline-flex w-0 flex-1 items-center justify-center hover:text-primary active:text-primary-darker my-0 -mr-px px-0 py-2 xs:py-4 sm:py-2 md:py-4 disabled:opacity-25">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            className="h-5 w-5 Icon "
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M21.03 2.97a3.578 3.578 0 0 1 0 5.06L9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L15.97 2.97a3.578 3.578 0 0 1 5.06 0ZM15 6.06 5.062 16a.75.75 0 0 0-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 0 0 8 18.938L17.94 9 15 6.06Zm2.03-2.03-.97.97L19 7.94l.97-.97a2.079 2.079 0 0 0-2.94-2.94Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </button>
                      </div>

                      <div className="-ml-px flex w-0 flex-1">
                        <button className="relative bg-transparent text-sm font-medium text-gray-60 hover:text-primary active:text-primary-darker inline-flex w-0 flex-1 items-center justify-center my-0 -mr-px px-0 py-2 xs:py-4 sm:py-2 md:py-4 disabled:opacity-25">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            className="h-5 w-5 Icon "
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75Zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5ZM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75Zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75Zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}
