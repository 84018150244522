import type { ReactNode } from "react";
import Link from "../../../_routing/Link";

export default function WorkspaceSettingLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="w-full">
      <div className="fixed inset-0 z-10 bg-slate-900/20 md-lg:hidden"></div>

      <div className="w-64 md-lg:w-[30%] lg:w-[40%] -ml-64 md-lg:ml-0 fixed z-20 h-full border-0 border-r border-solid border-gray-20 bg-dashboard transition-[margin-left] duration-300 !ml-0">
        <div className="x-custom-scrollbar ml-auto h-full overflow-y-auto">
          <div className="z-20 justify-end md-lg:ml-auto md-lg:max-w-[16rem] lg:flex">
            <nav className="x-custom-scrollbar min-w-[160px] select-none py-7 pl-7 pr-3">
              <div className="absolute right-2 top-2 md-lg:hidden">
                <button
                  type="button"
                  className="flex h-10 w-10 items-center justify-center bg-transparent p-2 dark:text-white"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className=" Icon "
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="m4.397 4.554.073-.084a.75.75 0 0 1 .976-.073l.084.073L12 10.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L13.061 12l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L12 13.061l-6.47 6.47a.75.75 0 0 1-1.06-1.061L10.939 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084-.073.084Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
              </div>
              <Link
                className="x-reset-link mt-5 flex items-center font-bold text-color-text hover:text-primary active:text-primary-chubb sm:mt-10"
                to="/w/3rzG1yGrEM"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className="text-lg Icon "
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.707 4.293a1 1 0 0 1 0 1.414L9.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span className="ml-2 text-lg">Workspace settings</span>
              </Link>
              <Link
                className="mb-4 mt-12 flex items-center !no-underline"
                to="/w/3rzG1yGrEM"
              >
                <img
                  className="pointer-events-none !select-none rounded-full object-contain hover:brightness-95 active:brightness-75 "
                  src="https://app.excalidraw.com//letter-avatars/t_indigo.png"
                  alt="test"
                  referrerPolicy="no-referrer"
                  style={{ width: "40px", height: "40px" }}
                />
                <div className="max-w-[140px]">
                  <span className="block overflow-hidden text-ellipsis pl-2 font-bold text-color-text !no-underline">
                    test
                  </span>
                </div>
              </Link>
              <div className="mb-4 mt-7 font-semibold">Workspace Settings</div>
              <Link
                aria-current="page"
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded bg-surface-primary-container hover:bg-surface-primary-container active:bg-surface-primary-container"
                to="/w/3rzG1yGrEM/settings"
              >
                Workspace settings
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to="/w/3rzG1yGrEM/settings/members"
              >
                Members
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to="/w/3rzG1yGrEM/settings/teams"
              >
                Teams &amp; Collections
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to=""
              >
                AI preferences
              </Link>
              <div className="mb-4 mt-7 font-semibold">
                Workspace Subscription
              </div>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to=""
              >
                Invoices
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to=""
              >
                Payment methods
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to=""
              >
                Billing information
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to=""
              >
                Change plan
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to=""
                style={{
                  color: "rgb(240, 62, 62)",
                }}
              >
                Cancel subscription
              </Link>
              <Link
                className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none active:text-on-surface box-border flex w-full items-center mt-1 px-3 py-1 !no-underline rounded"
                to=""
                style={{
                  color: "rgb(240, 62, 62)",
                  marginTop: "3em",
                }}
              >
                Delete Workspace
              </Link>
            </nav>
          </div>
        </div>
      </div>

      <div className="w-full max-w-7xl md-lg:pl-[30%] lg:max-w-[85%] lg:pl-[40%]">
        {children}
      </div>
    </div>
  );
}
