import { useAtomValue } from "jotai";
import { collabAPIAtom, isCallingAtom, isInCallAtom } from "../collab/Collab";
import JoinCallButton from "./JoinCallButton";

const CallButton = () => {
  const collabAPI = useAtomValue(collabAPIAtom);

  return (
    <div
      className="call-btn-wrapper"
      onClick={() => {
        collabAPI?.startCall();
      }}
    >
      <div className="w-fit" data-state="closed">
        <button
          type="button"
          className="excalidraw-button !border-0 shadow-[0_0_0_1px_var(--box-shadow-normal)] active:shadow-[--box-shadow-active] relative disabled:!cursor-not-allowed disabled:!opacity-50"
          style={
            {
              "--button-bg": "var(--color-surface-low)",
              "--button-width": "var(--lg-button-size)",
              "--button-height": "var(--lg-button-size)",
              "--box-shadow-normal": "var(--color-surface-lowest)",
              "--box-shadow-active": "var(--color-brand-active)",
            } as React.CSSProperties
          }
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className=" Icon "
            viewBox="0 0 24 24"
            fill="none"
            strokeWidth="2"
            stroke="currentColor"
            strokeLinecap="round"
            style={{
              width: "var(--xl-icon-size)",
              height: "var(--xl-icon-size)",
              flex: "0 0 auto",
            }}
          >
            <g
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default function CallHandleButton() {
  const isCalling = useAtomValue(isCallingAtom);
  const isInCall = useAtomValue(isInCallAtom);

  if (isCalling) {
    return <></>;
  }

  return isInCall ? <JoinCallButton /> : <CallButton />;
}
