export default function MyProfile() {
  return (
    <>
      <h2 className="my-3 text-xl font-bold">My Profile</h2>
      <div className="SettingsProfile">
        <div className="SettingsProfile-avatar">
          <img
            className="pointer-events-none !select-none rounded-full object-contain hover:brightness-95 active:brightness-75 "
            src="https://avatars.githubusercontent.com/u/49265862?v=4"
            alt="dungbui.dungbui.00"
            referrerPolicy="no-referrer"
            style={{
              width: "150px",
              height: "150px",
            }}
          />
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className="upload-icon Icon "
            viewBox="0 0 24 24"
            fill="none"
            stroke-width="2"
            stroke="currentColor"
            stroke-linecap="round"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="17 8 12 3 7 8"></polyline>
            <line x1="12" y1="3" x2="12" y2="15"></line>
          </svg>
          <div className="absolute bottom-1 right-4">
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="!static !transform-none Icon "
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17.5 12a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm0 2-.09.007a.5.5 0 0 0-.402.402L17 14.5V17h-2.502l-.09.009a.5.5 0 0 0-.402.402l-.008.09.008.09a.5.5 0 0 0 .402.402l.09.008H17v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008.09-.008a.5.5 0 0 0 .402-.402l.008-.09V18h2.504l.09-.007a.5.5 0 0 0 .402-.402l.008-.09-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H18v-2.5l-.008-.09a.5.5 0 0 0-.402-.403L17.5 14ZM13.925 2.502a2.25 2.25 0 0 1 1.94 1.11L16.679 5h2.071A3.25 3.25 0 0 1 22 8.25v4.56a6.517 6.517 0 0 0-1.499-1.077L20.5 8.25a1.75 1.75 0 0 0-1.75-1.75h-2.5a.75.75 0 0 1-.647-.37l-1.032-1.757a.75.75 0 0 0-.646-.37h-3.803a.75.75 0 0 0-.574.268l-.065.09L8.39 6.141a.75.75 0 0 1-.639.358h-2.5A1.75 1.75 0 0 0 3.5 8.25v9.5c0 .966.784 1.75 1.75 1.75h6.063c.173.533.412 1.037.709 1.5H5.25A3.25 3.25 0 0 1 2 17.75v-9.5A3.25 3.25 0 0 1 5.25 5h2.08l.875-1.424a2.25 2.25 0 0 1 1.917-1.073h3.803ZM12 8a4.502 4.502 0 0 1 4.283 3.114c-.5.095-.98.247-1.433.449A2.999 2.999 0 0 0 9 12.5c0 1.43 1 2.625 2.338 2.927a6.446 6.446 0 0 0-.31 1.468A4.501 4.501 0 0 1 12 8Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <h1 className="my-3 break-all text-3xl font-bold">
          dungbui.dungbui.00
        </h1>
        <button
          type="button"
          className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] mt-4 bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className="!h-8 !w-8 px-2 py-1 Icon "
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21.03 2.97a3.578 3.578 0 0 1 0 5.06L9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L15.97 2.97a3.578 3.578 0 0 1 5.06 0ZM15 6.06 5.062 16a.75.75 0 0 0-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 0 0 8 18.938L17.94 9 15 6.06Zm2.03-2.03-.97.97L19 7.94l.97-.97a2.079 2.079 0 0 0-2.94-2.94Z"
              fill="currentColor"
            ></path>
          </svg>
          <span className="py-1 pr-2">Rename my profile</span>
        </button>
      </div>
      <h2 className="my-3 text-xl font-bold">Account email</h2>
      <div className="mt-4 flex flex-row justify-between p-1">
        <div>dungbui.dungbui.00@gmail.com</div>
        <div>
          <button
            type="button"
            className="flex cursor-pointer items-center justify-center rounded bg-transparent text-base text-link hover:underline dark:text-white"
          >
            Edit
          </button>
        </div>
      </div>
      <hr className="mt-4 border-gray-20" />
      <h2 className="my-3 text-xl font-bold">Linked accounts </h2>
      <div className="mt-4 flex flex-row justify-between p-1 text-xs font-bold">
        <div>Email</div>
        <div>Sign-in provider</div>
      </div>
      <div className="mt-4 flex flex-row justify-between p-1">
        <div>dungbui.dungbui.00@gmail.com</div>
        <div>github.com</div>
      </div>
      <div className="mt-4 flex flex-row justify-between p-1">
        <div>dungbui.dungbui.00@gmail.com</div>
        <div>google.com</div>
      </div>
      <hr className="mt-4 border-gray-20" />
      <h2 className="mt-3 text-xl font-bold">Connect more sign-in providers</h2>
      <p className="mt-1 text-sm">
        You can also link sign-in provider accounts associated with a different
        email.
      </p>
      <div className="mt-4 flex">
        <button
          type="button"
          className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
        >
          Connect sign-in provider
        </button>
      </div>
    </>
  );
}
