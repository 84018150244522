import { PagePathEnum } from "../../pages/_routing/router";
import { useMatchRoute } from "../../pages/_routing/utils";
import type { BaseResponse } from "../http/base";
import { AbstractApiService } from "../http/base";
import type { IScene } from "../model/Scene";
import type { CreateSceneDto, UpdateSceneDto } from "./types";

class ScenceService extends AbstractApiService {
  private baseUrl(sceneId: string) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { matchPath } = useMatchRoute();
    const prefix = matchPath === PagePathEnum.PUBLIC_SCENCE ? "/public" : "";
    return `${prefix}/scenes/${sceneId}`;
  }
  async getSence<TResponse>(sceneId: string) {
    return this.getApi<IScene<TResponse>>(this.baseUrl(sceneId));
  }

  async create(payload: CreateSceneDto): Promise<BaseResponse<string>> {
    return this.postApi<CreateSceneDto, string>(`/scenes`, payload);
  }

  async update(
    sceneId: string,
    payload: UpdateSceneDto,
  ): Promise<BaseResponse<string>> {
    return this.putApi<UpdateSceneDto, string>(this.baseUrl(sceneId), payload);
  }
}

export const sceneService = new ScenceService();
