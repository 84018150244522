import {
  DashboardIcon,
  SearchIcon,
  SettingsIcon,
  TeamIcon,
} from "../../../packages/excalidraw/components/icons";
import Link from "../../pages/_routing/Link";
import clsx from "clsx";
import { useSelectedItem } from "../../share/hook/useSelectedItem";
const NavLink = ({
  icon,
  title,
  href,
  focused = false,
  onClick = () => {},
}: {
  icon: React.ReactNode;
  title: string;
  href: string;
  focused?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Link
      className={clsx(
        "text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none select-none x-reset-link flex items-center px-3 py-2 rounded cursor-pointer",
        focused ? "bg-surface-high !text-primary" : "",
      )}
      to={href}
      onClick={onClick}
    >
      <div className="flex items-center justify-center">{icon}</div>
      <div className="ml-2 flex items-center justify-center text-sm">
        {title}
      </div>
    </Link>
  );
};

export default function ToolBar() {
  const { isSelected, setSelectedItem } = useSelectedItem();
  return (
    <div className="mb-2 mt-2 px-2 pt-2">
      <div className="text-sm font-semibold bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none select-none x-reset-link flex items-center px-3 py-2 rounded cursor-pointer mb-3 !bg-surface-low !font-normal">
        <div className="flex items-center justify-center">
          <SearchIcon />
        </div>

        <div className="ml-2 flex flex-grow items-center text-sm">
          Quick search
        </div>

        <div className="ml-2 flex items-center justify-center text-[10px] text-gray-60">
          ⌘ + p
        </div>
      </div>

      <NavLink
        icon={<DashboardIcon />}
        title="Dashboard"
        href="/w/3rzG1yGrEM"
        focused={isSelected("DASHBOARD")}
        onClick={() => {
          setSelectedItem("DASHBOARD");
        }}
      />
      <NavLink
        icon={<SettingsIcon />}
        title="Workspace settings"
        href="/w/3rzG1yGrEM/settings"
      />

      <NavLink
        icon={<TeamIcon />}
        title="Team members"
        href="/w/3rzG1yGrEM/settings/members"
      />
    </div>
  );
}
