import { useGoogleLogin } from "@react-oauth/google";
import { authService } from "../services";
import type { IAuthUser } from "../model";
import { decodeJWT } from "../utils";
import useSetAuthUser from "./useSetAuthUser";
export default function useLogin() {
  const setAuthUser = useSetAuthUser();
  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      await authService.loginGoogle({ code });
      const accessToken = localStorage.getItem("accessToken") || "";
      const authUser = decodeJWT<IAuthUser>(accessToken);
      if (authUser) {
        setAuthUser(authUser);

        // setToast({
        //   ...toast,
        //   isShow: true,
        //   message: "Login successfully",
        // });
      }
    },
    onError: () => {},
    flow: "auth-code",
  });
  return login;
}
