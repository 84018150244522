import { useSetAtom } from "jotai";
import { authService } from "../services";
import { authUserAtom } from "../../app-jotai";
import { PagePathEnum } from "../../pages/_routing/router";
import { useContext } from "react";
import RouterContext from "../../pages/_routing/RouterContext";

export default function useLogout() {
  const setAuthUser = useSetAtom(authUserAtom);
  const { navigate } = useContext(RouterContext);
  return async () => {
    await authService.logout();
    setAuthUser(null);
    localStorage.removeItem("accessToken");
    navigate(PagePathEnum.SIGN_IN);
  };
}
