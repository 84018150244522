import { prepareElementsForExport } from "../../packages/excalidraw/data";
import { canvasToBlob } from "../../packages/excalidraw/data/blob";
import type { ExcalidrawElement } from "../../packages/excalidraw/element/types";
import { exportToCanvas } from "../../packages/excalidraw/scene/export";
import type { AppState, BinaryFiles } from "../../packages/excalidraw/types";
import { cloneJSON } from "../../packages/excalidraw/utils";
import { PagePathEnum } from "../pages/_routing/router";
import { trimCurrentPath } from "../pages/_routing/utils";

export function decodeJWT<T = any>(token: string): T {
  // Split the token into its parts
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid JWT token");
  }

  // Base64 decode the payload
  const payload = parts[1];
  const decodedPayload = atob(payload);

  // Parse the JSON string
  return JSON.parse(decodedPayload) as T;
}

export function getUserId() {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    return null;
  }
  const { userId } = decodeJWT<{ userId: string }>(accessToken);
  return userId as string;
}
export const createQueryParams = (
  params: Record<string, string | number | undefined>,
) => {
  const query = Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return query ? `?${query}` : "";
};

export default function exportThumb({
  appState,
  files,
  elements,
}: {
  appState: AppState;
  files: BinaryFiles;
  elements: readonly ExcalidrawElement[];
}): Promise<Blob> {
  const { exportedElements, exportingFrame } = prepareElementsForExport(
    cloneJSON(elements),
    cloneJSON(appState),
    false,
  );

  const tempCanvas = exportToCanvas(exportedElements, appState, files, {
    exportBackground: true,
    exportPadding: 10,
    viewBackgroundColor: "white",
    exportingFrame,
  });
  return canvasToBlob(tempCanvas);
}

export function useParams(inputRoutes: PagePathEnum[] = []) {
  const currentPath = trimCurrentPath(window.location.pathname);
  const routes = inputRoutes.length ? inputRoutes : Object.values(PagePathEnum);
  const params: Record<string, string> = {};
  let match = null;
  for (const path of routes) {
    const pattern = new RegExp(
      `^${path.replace(/\/\*/g, "(?:/.*)?").replace(/:[^\s/]+/g, "([^/]+)")}$`,
    );
    match = currentPath.match(pattern);
    if (!match) {
      continue;
    }

    const extractedParams = path.split("/");
    const currentPathSegments = currentPath.split("/");
    if (extractedParams?.length && currentPathSegments?.length) {
      extractedParams.forEach((param, index) => {
        if (param.startsWith(":")) {
          params[param.substring(1)] = currentPathSegments[index];
        }
      });
    }
    break;
  }

  return params;
}
