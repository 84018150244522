export default function TeamSetting() {
  return (
    <main className="relative box-border px-4 pb-14 pt-28 sm:px-12">
      <a className="x-reset-link flex" href="/o/3rzG1yGrEM">
        <button
          type="button"
          className="h-6 w-6 rounded flex items-center justify-center bg-primary hover:bg-brand-hover active:bg-brand-active disabled:bg-surface-low disabled:text-border-outline-variant text-md text-surface-lowest absolute right-4 top-4 select-none !rounded-full sm:right-8 sm:top-8 active:scale-[.98]"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            className=" Icon "
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="m4.397 4.554.073-.084a.75.75 0 0 1 .976-.073l.084.073L12 10.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L13.061 12l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L12 13.061l-6.47 6.47a.75.75 0 0 1-1.06-1.061L10.939 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084-.073.084Z"
              fill="currentColor"
            ></path>
          </svg>
          <div className="shortcut absolute left-0 top-8 w-full text-xs text-gray-60">
            esc
          </div>
        </button>
      </a>
      <div className="SettingsContainer-content-body">
        <div>
          <h2 className="my-3 flex select-none items-center text-xl font-bold">
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="mr-2 Icon "
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill="currentColor"
                d="M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm0 1.5h-5.5a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759 2.168 0 2.995-.564 2.933-1.757V16.75a.25.25 0 0 0-.25-.25Zm-11-6.5h4.376a4.007 4.007 0 0 0-.095 1.5H3.75a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759.462 0 .863-.026 1.207-.077a2.743 2.743 0 0 0-1.173 1.576l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.755 2.755 0 0 0-1.218-1.606c.387.072.847.108 1.387.108 2.168 0 2.995-.564 2.933-1.757V11.75a.25.25 0 0 0-.25-.25h-4.28a4.05 4.05 0 0 0-.096-1.5h4.376ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-11 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm11 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
              ></path>
            </svg>{" "}
            Teams
            <button
              type="button"
              className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] ml-auto bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
            >
              Create team
            </button>
          </h2>
          <div className="bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none text-base font-normal listItem box-border !grid w-full grid-cols-6 gap-x-4">
            <div className="col-span-4 flex items-center">
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                className="mr-2 Icon "
                viewBox="0 0 24 24"
                fill="none"
                style={{
                  flex: "0 0 auto",
                  fill: "rgb(112, 72, 232)",
                  stroke: "rgb(112, 72, 232)",
                  transform: "translateY(1px)",
                }}
              >
                <path
                  fill="currentColor"
                  d="M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm0 1.5h-5.5a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759 2.168 0 2.995-.564 2.933-1.757V16.75a.25.25 0 0 0-.25-.25Zm-11-6.5h4.376a4.007 4.007 0 0 0-.095 1.5H3.75a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759.462 0 .863-.026 1.207-.077a2.743 2.743 0 0 0-1.173 1.576l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.755 2.755 0 0 0-1.218-1.606c.387.072.847.108 1.387.108 2.168 0 2.995-.564 2.933-1.757V11.75a.25.25 0 0 0-.25-.25h-4.28a4.05 4.05 0 0 0-.096-1.5h4.376ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-11 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm11 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
                ></path>
              </svg>
              <div>Test Team</div>
            </div>
            <div className="col-span-2 ml-auto whitespace-nowrap">1 member</div>
          </div>
          <div className="bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none text-base font-normal listItem box-border !grid w-full grid-cols-6 gap-x-4">
            <div className="col-span-4 flex items-center">
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                className="mr-2 Icon "
                viewBox="0 0 24 24"
                fill="none"
                style={{
                  flex: "0 0 auto",
                  fill: "rgb(214, 51, 108)",
                  stroke: "rgb(214, 51, 108)",
                  transform: "translateY(1px)",
                }}
              >
                <path
                  fill="currentColor"
                  d="M14.75 15c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297-2.91 0-4.567-1.09-4.567-3.259v-1c0-.966.784-1.75 1.75-1.75h5.5Zm0 1.5h-5.5a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759 2.168 0 2.995-.564 2.933-1.757V16.75a.25.25 0 0 0-.25-.25Zm-11-6.5h4.376a4.007 4.007 0 0 0-.095 1.5H3.75a.25.25 0 0 0-.25.25v1c0 1.176.887 1.759 3.067 1.759.462 0 .863-.026 1.207-.077a2.743 2.743 0 0 0-1.173 1.576l-.034.001C3.657 16.009 2 14.919 2 12.75v-1c0-.966.784-1.75 1.75-1.75Zm16.5 0c.966 0 1.75.784 1.75 1.75l-.001.962c.117 2.19-1.511 3.297-4.432 3.297l-.169-.002a2.755 2.755 0 0 0-1.218-1.606c.387.072.847.108 1.387.108 2.168 0 2.995-.564 2.933-1.757V11.75a.25.25 0 0 0-.25-.25h-4.28a4.05 4.05 0 0 0-.096-1.5h4.376ZM12 8a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm11 0a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm-11 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm11 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
                ></path>
              </svg>
              <div>Test Team</div>
            </div>
            <div className="col-span-2 ml-auto whitespace-nowrap">1 member</div>
          </div>
        </div>
        <div>
          <h2 className="my-3 flex select-none items-center text-xl font-bold">
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="mr-2 Icon "
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20.496 5.627A2.25 2.25 0 0 1 22 7.75v10A4.25 4.25 0 0 1 17.75 22h-10a2.25 2.25 0 0 1-2.123-1.504l2.097.004H17.75a2.75 2.75 0 0 0 2.75-2.75v-10l-.004-.051V5.627ZM17.246 2a2.25 2.25 0 0 1 2.25 2.25v12.997a2.25 2.25 0 0 1-2.25 2.25H4.25A2.25 2.25 0 0 1 2 17.247V4.25A2.25 2.25 0 0 1 4.25 2h12.997Zm0 1.5H4.25a.75.75 0 0 0-.75.75v12.997c0 .414.336.75.75.75h12.997a.75.75 0 0 0 .75-.75V4.25a.75.75 0 0 0-.75-.75Z"
                fill="currentColor"
              ></path>
            </svg>{" "}
            Collections
          </h2>
          <div className="listItem pointer-events-none box-border !grid w-full grid-cols-6 gap-x-4">
            <div className="col-span-4 font-bold">Name</div>
            <div className="col-span-2 ml-auto text-center font-bold">
              Access
            </div>
          </div>
          <div
            className="scrollbar-sm"
            style={{ minHeight: "48px", maxHeight: "260px", overflowY: "auto" }}
          >
            <div className="bg-[transparent] hover:bg-surface-high active:bg-surface-high group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none text-base font-normal listItem box-border !grid w-full grid-cols-6 gap-x-4">
              <div className="col-span-4 flex items-center">
                <div className="mr-2 flex w-6 items-center justify-center">
                  <div>😱</div>
                </div>
                <div>TEsst</div>
              </div>
              <div className="col-span-2 ml-auto space-y-1 text-center">
                everyone
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
