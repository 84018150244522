import {
  DefaultCollectionIcon,
  ThreeDotsIcon,
} from "../../../packages/excalidraw/components/icons";
import clsx from "clsx";
import Link from "../../pages/_routing/Link";
export default function CollectionItem({
  name,
  icon,
  focused = false,
  showIcon = true,
  editable = true,
  to,
  onClick = () => {},
}: {
  name: string;
  icon?: JSX.Element;
  focused?: boolean;
  showIcon?: boolean;
  editable?: boolean;
  to: string;
  onClick?: () => void;
}) {
  return (
    <Link to={to} onClick={onClick} className="hover:no-underline">
      <div className="outline-none">
        <div
          className={clsx(
            "text-sm font-semibold bg-[transparent]",
            focused
              ? "bg-primary hover:bg-primary-darker active:bg-primary-darker text-surface-lowest"
              : "hover:bg-surface-high active:bg-surface-high",
            "group-data-[state=open]:bg-surface-high border border-transparent active:border-brand-active text-on-surface focus-visible:bg-surface-high focus-visible:outline-none group mt-1 flex items-center rounded px-3 py-[6px] relative cursor-pointer select-none pr-12",
          )}
        >
          <div className="-ml-1 flex w-6 justify-center">
            <div className="flex items-center justify-center ">
              <button
                type="button"
                className={clsx(
                  "m-0 h-6 w-6 overflow-hidden rounded bg-transparent p-0 text-inherit",
                  focused
                    ? "hover:!bg-primary-darkest"
                    : "hover:!bg-surface-primary-container",
                )}
                title="Change icon"
              >
                <div className="flex h-full w-full items-center justify-center text-lg">
                  {!showIcon ? null : icon ? icon : <DefaultCollectionIcon />}
                </div>
              </button>
            </div>
          </div>
          <div className="ml-2 items-center overflow-hidden overflow-ellipsis text-sm">
            {name}
          </div>

          {editable ? (
            <div className="absolute right-3 flex cursor-pointer items-center justify-center sm:invisible sm:group-hover:visible ">
              <div className="cursor-pointer rounded hover:bg-surface-primary-container flex items-center text-on-surface">
                <div className="flex h-6 w-6 items-center justify-center hover:bg-surface-high">
                  <ThreeDotsIcon />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  );
}
