import { ThreeDotsIcon } from "../../../../packages/excalidraw/components/icons";
import Link from "../../_routing/Link";
import { useEffect, useState } from "react";
const HTTP_STORAGE_BACKEND_URL = import.meta.env.VITE_HTTP_STORAGE_BACKEND_URL;

type ScenceItemProps = {
  id: string;
  name: string;
  creator: string;
  lastUpdated: string;
  isPrivate: boolean;
};
const PrivateIcon = () => (
  <div className="flex min-w-0 items-center justify-center text-sm text-gray-70">
    <div className="w-fit" data-state="closed">
      <span className="flex items-center justify-center ">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          className="ml-1 Icon "
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 20 10.25v9.5A2.25 2.25 0 0 1 17.75 22H6.25A2.25 2.25 0 0 1 4 19.75v-9.5A2.25 2.25 0 0 1 6.25 8H8V6a4 4 0 0 1 4-4Zm5.75 7.5H6.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h11.5a.75.75 0 0 0 .75-.75v-9.5a.75.75 0 0 0-.75-.75Zm-5.75 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-10A2.5 2.5 0 0 0 9.5 6v2h5V6A2.5 2.5 0 0 0 12 3.5Z"
            fill="currentColor"
          ></path>
        </svg>
      </span>
    </div>
  </div>
);

export default function ScenceItem({
  id,
  name,
  creator,
  lastUpdated,
  isPrivate,
}: ScenceItemProps) {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");

  useEffect(() => {
    const fetchThumbnail = async () => {
      const response = await fetch(`${HTTP_STORAGE_BACKEND_URL}/files/${id}`);
      const thumbnail = await response.blob();
      const thumbnailUrl = URL.createObjectURL(thumbnail);
      setThumbnailUrl(thumbnailUrl);
    };

    fetchThumbnail();

    // Clean up the URL object when the component unmounts
    return () => {
      if (thumbnailUrl) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Link
      className="group w-full relative flex flex-col x-reset-link overflow-hidden rounded-xl box-border border border-solid border-transparent hover:border-primary active:border-primary-darkest"
      to={`/s/${id}`}
    >
      <span data-state="closed" className="contents">
        <div className="relative flex w-full">
          <div
            className="x-dark-image-preview flex aspect-[4/3] w-full flex-shrink-0 items-center justify-center rounded-xl"
            style={{
              backgroundColor: "rgb(255, 255, 255)",
            }}
          >
            <img
              className="flex h-[140px] max-w-[140px] px-3 pb-9 pt-3 sm:h-[145px] sm:max-w-[192px] sm:px-2 sm:pb-2 sm:pt-2 pointer-events-none relative w-full object-contain"
              src={thumbnailUrl}
              alt={name}
            />
          </div>
          <div
            className="absolute bottom-1 right-2 rounded bg-white/80 p-[2px] px-2 text-[10px] text-gray-70 dark:bg-default"
            title="Updated 2024-07-20 02:7I"
          >
            {lastUpdated}
          </div>
          <div className="absolute left-1 top-2 w-full sm:bottom-1 sm:top-auto"></div>
        </div>
        <div className="mt-1.5 box-border px-2 pb-2 xl:mt-2">
          <div className="mb-0.5 box-border truncate text-sm font-semibold text-color-text">
            {name}
          </div>
          <div className="flex justify-between gap-1">
            <div
              className="flex items-center justify-center text-xs text-color-text"
              title={`Scene created by ${creator}`}
            >
              <div
                className="truncate"
                style={{
                  maxWidth: "120px",
                }}
              >
                by {creator}
              </div>
            </div>
            {isPrivate ? <PrivateIcon /> : null}
          </div>
        </div>
        <div className="invisible absolute right-2 top-2 group-hover:visible">
          <div>
            <div
              className="cursor-pointer rounded hover:bg-surface-primary-container flex items-center text-on-surface undefined"
              id="radix-:r27:"
              aria-haspopup="menu"
              aria-expanded="false"
              data-state="closed"
            >
              <div className="flex h-6 w-6 items-center justify-center hover:bg-surface-high">
                <ThreeDotsIcon />
              </div>
            </div>
          </div>
          <div></div>
        </div>

        <div className="contents"></div>
      </span>
    </Link>
  );
}
