import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useAtomValue } from "jotai";
import { sceneIdAtom, sencesAtom } from "../../../excalidraw-app/app-jotai";
import { sceneService } from "../../../excalidraw-app/share/services/scene.service";

const INPUT_DEBOUNCE_TIME = 500;
export const SceneName = () => {
  const scences = useAtomValue(sencesAtom);
  const sceneId = useAtomValue(sceneIdAtom);
  const [name, setName] = useState("");
  const updateSceneName = debounce(async () => {
    if (!sceneId) return;
    // const debounceTime = parseInt(
    //   localStorage.getItem("SCENE_NAME_DEBOUNCE_TIME") || "0",
    //   10,
    // );
    // if (Date.now() < debounceTime) return;
    await sceneService.update(sceneId, { name });
    // localStorage.removeItem("SCENE_NAME_DEBOUNCE_TIME");
  }, INPUT_DEBOUNCE_TIME);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setName(inputValue);
    //set to local storage to prevent asunchronous when set state
    // localStorage.setItem(
    //   "SCENE_NAME_DEBOUNCE_TIME",
    //   (Date.now() + INPUT_DEBOUNCE_TIME).toString(),
    // );
    updateSceneName();
  };
  useEffect(() => {
    if (sceneId) {
      const scene = scences.find((scene) => scene.id === sceneId);
      if (scene) {
        setName(scene.name);
      }
    }
  }, [sceneId, scences]);
  return (
    <input
      className="w-full inset-0 truncate resize-none cursor-pointer !p-0 !border-0 !shadow-none bg-transparent focus:cursor-text hover:!text-primary focus:!text-color-text focus:!outline-none focus:!ring-0 focus:!ring-offset-0"
      value={name}
      onChange={onChange}
    />
  );
};
