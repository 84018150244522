export default function Preference() {
  return (
    <>
      <h2 className="my-3 text-xl font-bold">Preferences</h2>
      <div className="mt-4 flex flex-row items-center justify-between p-1">
        <div className="text-sm font-bold">
          App theme
          <div className="text-xs font-normal text-gray-60">⌥ + ⇧ + D</div>
        </div>
        <div>
          <div>
            <div
              className="cursor-pointer rounded hover:bg-surface-primary-container ml-2 flex items-center text-on-surface max-w-[170px] px-2 py-1"
              itemType="button"
              id="radix-:rau:"
              aria-haspopup="menu"
              aria-expanded="false"
              data-state="closed"
            >
              <div className="mr-1 truncate">System</div>
              <div className="flex h-6 w-6 items-center justify-center ml-2">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className=" Icon "
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill="currentColor"
                    d="M4.22 8.47a.75.75 0 0 1 1.06 0L12 15.19l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L4.22 9.53a.75.75 0 0 1 0-1.06Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div className="pointer-events-auto "></div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between p-1">
        <div className="text-sm font-bold">
          <div>Auto-redirect from excalidraw.com</div>
          <div className="text-xs font-normal text-gray-60">
            When you access excalidraw.com you will be auto-redirected to
            app.excalidraw.com
          </div>
        </div>
        <div>
          <input
            className="ToggleButton ToggleButton-left"
            type="checkbox"
            id="input-checkbox-10"
            tabIndex={-1}
            checked
          />
          <label
            htmlFor="input-checkbox-10"
            style={{ transform: "translateY(1px)" }}
          ></label>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between p-1">
        <div className="text-sm font-bold">
          <div>Show old fonts</div>
          <div className="text-xs font-normal text-gray-60">
            You will be able to pick old fonts inside the font picker which have
            been replaced or removed from the editor. <br />
            These fonts are tagged with an "old" badge within the font picker
            list. Note that fonts in old drawings that use them will always list
            these regardless of this setting.
          </div>
        </div>
        <div>
          <input
            className="ToggleButton ToggleButton-left"
            type="checkbox"
            id="input-checkbox-11"
            tabIndex={-1}
            checked
          />
          <label
            htmlFor="input-checkbox-11"
            style={{ transform: "translateY(1px)" }}
          ></label>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between p-1">
        <div className="text-sm font-bold">
          <div>Force Long-polling</div>
          <div className="text-xs font-normal text-gray-60">
            Forcing long-polling should help with connectivity issues. (company
            VPN, unstable network, etc.), <br />
            but could potentially slow down the application. This settings is
            going to be set only on this device and will reload the page.
          </div>
        </div>
        <div>
          <input
            className="ToggleButton ToggleButton-left"
            type="checkbox"
            id="input-checkbox-12"
            tabIndex={-1}
          />
          <label
            htmlFor="input-checkbox-12"
            style={{ transform: "translateY(1px)" }}
          ></label>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between p-1">
        <div className="text-sm font-bold">
          Initial app page
          <div className="text-xs font-normal text-gray-60">
            What page to load when you open app.excalidraw.com
          </div>
        </div>
        <div>
          <div>
            <div
              className="cursor-pointer rounded hover:bg-surface-primary-container ml-2 flex items-center text-on-surface max-w-[170px] px-2 py-1"
              itemType="button"
              id="radix-:rb0:"
              aria-haspopup="menu"
              aria-expanded="false"
              data-state="closed"
            >
              <div className="mr-1 truncate">Dashboard</div>
              <div className="flex h-6 w-6 items-center justify-center ml-2">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className=" Icon "
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill="currentColor"
                    d="M4.22 8.47a.75.75 0 0 1 1.06 0L12 15.19l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L4.22 9.53a.75.75 0 0 1 0-1.06Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div className="pointer-events-auto "></div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="-mb-2 p-1 text-lg font-semibold">
          Audio device settings
        </div>
        <div className="mt-4 flex flex-row items-center justify-between p-1">
          <div className="text-sm font-bold">
            Audio input device
            <div className="text-xs font-normal text-gray-60">
              Select the audio input device for voice hangouts
            </div>
          </div>
          <div>
            <div>
              <div
                className="cursor-pointer rounded hover:bg-surface-primary-container ml-2 flex items-center text-on-surface max-w-[170px] px-2 py-1"
                itemType="button"
                id="radix-:rb2:"
                aria-haspopup="menu"
                aria-expanded="false"
                data-state="closed"
              >
                <div className="mr-1 truncate">
                  Default - Unknown USB Audio Device (046d:0825)
                </div>
                <div className="flex h-6 w-6 items-center justify-center ml-2">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className=" Icon "
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M4.22 8.47a.75.75 0 0 1 1.06 0L12 15.19l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L4.22 9.53a.75.75 0 0 1 0-1.06Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <div className="pointer-events-auto "></div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-between p-1">
          <div className="text-sm font-bold">
            Audio output device
            <div className="text-xs font-normal text-gray-60">
              Select the audio output device for voice hangouts
            </div>
          </div>
          <div>
            <div>
              <div
                className="cursor-pointer rounded hover:bg-surface-primary-container ml-2 flex items-center text-on-surface max-w-[170px] px-2 py-1"
                itemType="button"
                id="radix-:rb4:"
                aria-haspopup="menu"
                aria-expanded="false"
                data-state="closed"
              >
                <div className="mr-1 truncate">
                  Default - Mac mini Speakers (Built-in)
                </div>
                <div className="flex h-6 w-6 items-center justify-center ml-2">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className=" Icon "
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M4.22 8.47a.75.75 0 0 1 1.06 0L12 15.19l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L4.22 9.53a.75.75 0 0 1 0-1.06Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <div className="pointer-events-auto "></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
