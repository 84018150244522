import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "jotai";
import { appJotaiStore } from "../../app-jotai";
interface IRootProviderProps {
  children: React.ReactNode;
}

export default function RootProvider({ children }: IRootProviderProps) {
  return (
    <Provider unstable_createStore={() => appJotaiStore}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        {children}
      </GoogleOAuthProvider>
    </Provider>
  );
}
