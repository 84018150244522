import { createContext } from "react";
import { PagePathEnum } from "./router";
import type { IRouterState } from "./types";

const intiData: IRouterState = {
  currentPath: PagePathEnum.HOME,
  pathStates: {},
  navigate: () => {},
};

const RouterContext = createContext<IRouterState>(intiData);

export default RouterContext;
