import { useAtomValue, useSetAtom } from "jotai";
import { collectionIdAtom, collectionListAtom } from "../../app-jotai";
import { useSelectedItem } from "./useSelectedItem";
import { SIDEBAR_ITEM } from "../constants";

export default function useSetSelectCollection() {
  const setCollectionId = useSetAtom(collectionIdAtom);
  const { setSelectedItem } = useSelectedItem();
  const collectionList = useAtomValue(collectionListAtom);

  return (collectionId: string) => {
    if (collectionId === SIDEBAR_ITEM.PRIVATE_COLLECTION) {
      const privateCollection = collectionList.find((item) => item.isPrivate);
      if (!privateCollection) {
        return;
      }
      collectionId = privateCollection.id;
    }
    setCollectionId(collectionId);
    setSelectedItem(collectionId);
  };
}
