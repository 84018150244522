import { useContext } from "react";
import RouterContext from "../../pages/_routing/RouterContext";
import { sceneService } from "../services/scene.service";
import type { CreateSceneDto } from "../services/types";
import { useAtomValue } from "jotai";
import { collectionListAtom } from "../../app-jotai";
import { SIDEBAR_ITEM } from "../constants";
import useRefreshWorkspace from "./useRefreshWorkspace";

export default function useCreateScence() {
  const { navigate } = useContext(RouterContext);
  const collectionList = useAtomValue(collectionListAtom);
  const refreshWorkspace = useRefreshWorkspace();
  return async (payload: CreateSceneDto) => {
    if (payload.collectionId === SIDEBAR_ITEM.PRIVATE_COLLECTION) {
      const privateCollection = collectionList.find((item) => item.isPrivate);
      if (!privateCollection) {
        return;
      }
      payload.collectionId = privateCollection.id;
    }
    const { data: scenceId } = await sceneService.create(payload);
    await refreshWorkspace();
    if (scenceId) {
      navigate(`/s/${scenceId}`);
    }
  };
}
