// const tests = [
//   {
//     path: "/w",
//     currentPath: "/w",
//     expected: true,
//   },
//   {
//     path: "/w/",
//     currentPath: "/w",
//     expected: true,
//   },
//   {
//     path: "/w/*",
//     currentPath: "/w",
//     expected: true,
//   },
//   {
//     path: "/w/:id",
//     currentPath: "/w/123",
//     expected: true,
//     params: { id: "123" },
//   },
// ];

import { APP_NAME } from "../../../packages/excalidraw/constants";
import { PagePathEnum, ROUTE_CONFIG } from "./router";

// let matchRoutes = (path, currentPath) => {
//   const pattern = new RegExp(
//     `^${path
//       .replace(/\/\*/g, "(?:/.*)?") // Handle optional wildcard segments
//       .replace(/:[^\s/]+/g, "([^/]+)")}$`, // Capture parameters
//   );
//   return currentPath.match(pattern);
// };

// tests.forEach(({ path, currentPath }) => {
//     const match = matchRoutes(path, currentPath)
//     const params = {}
//     if (match) {
//     for (let i = 0; i < match.length; i++) {
//       const param = match[i].substring(1);
//       if (!param) {
//         continue;
//       }
//       params[param] = (match)[i + 1];
//     }
//   }
//   console.log({
//     path,
//     currentPath,
//     match,
//     params
//   });
// });
export function trimCurrentPath(currentPath = "") {
  let trimmedPath = currentPath.trim();
  if (trimmedPath.endsWith("/")) {
    trimmedPath = trimmedPath.slice(0, -1);
  }
  if (!trimmedPath.startsWith("/")) {
    trimmedPath = `/${trimmedPath}`;
  }
  return trimmedPath;
}
export const useMatchRoute = (inputPath = window.location.pathname) => {
  const currentPath = trimCurrentPath(inputPath);
  const routes = Object.values(PagePathEnum);
  const params: Record<string, string> = {};
  let matchPath = PagePathEnum.HOME;
  let match = null;
  for (const path of routes) {
    const pattern = new RegExp(
      `^${path.replace(/\/\*/g, "(?:/.*)?").replace(/:[^\s/]+/g, "([^/]+)")}$`,
    );
    match = currentPath.match(pattern);
    if (match) {
      matchPath = path;
      const extractedParams = path.split("/");
      const currentPathSegments = currentPath.split("/");
      if (extractedParams?.length && currentPathSegments?.length) {
        extractedParams.forEach((param, index) => {
          if (param.startsWith(":")) {
            params[param.substring(1)] = currentPathSegments[index];
          }
        });
      }
      break;
    }
  }
  const paramConfig = ROUTE_CONFIG[matchPath]?.paramConfig;
  const isValidParam = !paramConfig
    ? true
    : Object.entries(paramConfig).every(([key, { validate = () => true }]) => {
        const paramValue = params[key];
        if (!paramValue) {
          return false;
        }
        const isValid = validate(paramValue);
        if (!isValid) {
          console.error(
            `Validation failed for parameter: ${key}, Value: ${paramValue}`,
          );
          return false;
        }
        return true;
      });
  const isValidURL = match && isValidParam;

  if (!isValidURL) {
    window.history.replaceState({}, APP_NAME, window.location.origin);
  }

  return {
    matchPath,
    params,
  };
};
