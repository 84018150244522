import { atom, unstable_createStore } from "jotai";
import type {
  IWorkspace,
  IAuthUser,
  BasicWorkspaceResponse,
} from "./share/model";
import type { CSSProperties } from "react";
import { SIDEBAR_ITEM } from "./share/constants";

export const appJotaiStore = unstable_createStore();
//Auth
export const authUserAtom = atom<IAuthUser | null>(null);

//Workspace
export const workspaceAtom = atom<IWorkspace | null>(null);

export const workspaceListAtom = atom<BasicWorkspaceResponse[]>(
  (get) => get(workspaceAtom)?.workspaces || [],
);
export const workspaceIdAtom = atom((get) => get(workspaceAtom)?.id);

//Collection
export const collectionListAtom = atom((get) => {
  const workspace = get(workspaceAtom);
  return workspace?.collections || [];
});
export const collectionIdAtom = atom<string | null>(null);
export const privateCollectionAtom = atom(
  (get) =>
    get(collectionListAtom).find((collection) => collection.isPrivate) || {
      id: SIDEBAR_ITEM.PRIVATE_COLLECTION,
      name: "Private",
      isPrivate: true,
    },
);
//Scene
export const sencesAtom = atom((get) => {
  const collectionId = get(collectionIdAtom);
  return (
    get(collectionListAtom).find(
      (item) =>
        item.id === collectionId ||
        (collectionId === SIDEBAR_ITEM.PRIVATE_COLLECTION && item.isPrivate),
    )?.scenes || []
  );
});
export const sceneIdAtom = atom<string | null>(null);
export const permissionAtom = atom<{ value: number } | null>(null);
export const isDisabledActionAtom = atom((get) => {
  return get(permissionAtom)?.value === 0;
});
//Sidebar
export const sidebarSelectedItemAtom = atom<string>("");
export const visibleLeftSidebarAtom = atom(false);

//Toast
export const toastAtom = atom<{
  isShow: boolean;
  message: string;
  onClose: () => void;
  closable?: boolean;
  duration?: number;
  style?: CSSProperties;
}>({
  isShow: false,
  message: "",
  onClose: () => {},
  closable: true,
  duration: 5000,
  style: {},
});
