import { useAtom } from "jotai";
import { mapWorkspaceRoleToText, type WorkspaceRoleEnum } from "../share/enum";
import { workspaceAtom } from "../app-jotai";
import { Avatar } from "../../packages/excalidraw/components/Avatar";

export default function WorkspaceItem({
  id,
  name,
  role,
  thumbnail,
  isSelected = false,
}: {
  id: string;
  name: string;
  role: WorkspaceRoleEnum;
  thumbnail: string;
  isSelected?: boolean;
}) {
  const [workspace, setWorkspace] = useAtom(workspaceAtom);
  const onSelectWorkspace = () => {
    if (id === workspace?.id) {
      return;
    }
    const workspaces = workspace?.workspaces || [];
    const selectedWorkspace = workspaces.find(
      (workspace) => workspace.id === id,
    );
    if (!selectedWorkspace) {
      return;
    }
    setWorkspace({
      ...selectedWorkspace,
      workspaces: workspaces.sort((a, b) => {
        if (a.id === id) {
          return -1;
        }
        if (b.id === id) {
          return 1;
        }
        return 0;
      }),
    });
    localStorage.setItem("workspaceId", id);
  };
  return (
    <div
      role="menuitem"
      className="box-border w-full flex items-center text-sm text-color-text hover:text-primary focus-visible:text-primary p-2 rounded hover:bg-surface-high cursor-pointer focus:outline-none focus-visible:bg-surface-high"
      tabIndex={-1}
      onClick={() => onSelectWorkspace()}
    >
      <div className="flex flex-shrink-0 items-center justify-center">
        <Avatar
          color=""
          onClick={() => {}}
          src={thumbnail}
          className="mr-3 h-8 w-8 rounded-full bg-primary hover:bg-brand-hover focus-visible:bg-brand-hover active:bg-brand-active text-center text-base font-bold text-surface-lowest focus:outline-none"
          name={name}
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>

      <div className="flex min-w-0 flex-col leading-tight">
        <span className="truncate font-bold">{name}</span>
        <span className="text-xs text-gray-50">
          {mapWorkspaceRoleToText(role)}
        </span>
      </div>
      {isSelected ? (
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          className="ml-auto h-6 w-6 pl-2 text-primary Icon Icon-check"
          viewBox="0 0 24 24"
          fill="none"
          stroke-width="2"
          stroke="currentColor"
          stroke-linecap="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      ) : (
        <></>
      )}
    </div>
  );
}
