import App from "../../App";
import { RootLayout } from "../RootLayout";
import WorkspaceSetting from "../workspace/[id]/settings/WorkspaceSetting";
import { WorkSpace } from "../workspace/WorkSpace";
import WorkspaceSettingLayout from "../workspace/[id]/settings/_layout";
import MemberSetting from "../workspace/[id]/settings/members/MemberSetting";
import TeamSetting from "../workspace/[id]/settings/teams/TeamSetting";
import Notification from "../workspace/[id]/notification/Notification";
import AccountLayout from "../workspace/[id]/account/_layout";
import MyProfile from "../workspace/[id]/account/my-profile";
import MyWorkspace from "../workspace/[id]/account/my-workspaces";
import Preference from "../workspace/[id]/account/preferences";
import SignIn from "../signin/SignIn";
import Empty from "./Empty";

export enum PagePathEnum {
  HOME = "/",
  SIGN_IN = "/sign-in",
  WORKSPACE = "/w/:id",
  NOTIFICATION = "/w/:id/notifications",
  MEMBER_SETTING = "/w/:id/settings/members",
  TEAM_SETTING = "/w/:id/settings/teams",
  WORKSPACE_SETTING = "/w/:id/settings",
  WORKSPACE_COLECTION = "/w/:id/:collectionId",
  SCENE = "/s/:sceneId",
  PUBLIC_SCENCE = "/p/s/:sceneId",
  MY_PROFILE = "/account/my-profile",
  MY_WORKSPACE = "/account/my-workspaces",
  PREFERENCE = "/account/preferences",
}

export const COLAB_PATHS = [PagePathEnum.SCENE, PagePathEnum.PUBLIC_SCENCE];
export const PUBLIC_PATHS = [PagePathEnum.PUBLIC_SCENCE, PagePathEnum.SIGN_IN];
export const WOKSPACE_PATHS = [
  PagePathEnum.WORKSPACE,
  PagePathEnum.WORKSPACE_COLECTION,
];
export const ROUTE_CONFIG: Record<
  PagePathEnum,
  {
    parent?: (any: any) => JSX.Element;
    component: (any: any) => JSX.Element;
    paramConfig?: Record<
      string,
      {
        validate?: (value: string) => boolean;
        transform?: (value: string) => any;
      }
    >;
  }
> = {
  [PagePathEnum.HOME]: {
    component: Empty,
  },
  [PagePathEnum.SIGN_IN]: {
    component: SignIn,
  },
  [PagePathEnum.WORKSPACE]: {
    parent: RootLayout,
    component: WorkSpace,
  },
  [PagePathEnum.NOTIFICATION]: {
    parent: RootLayout,
    component: Notification,
  },

  [PagePathEnum.MEMBER_SETTING]: {
    parent: WorkspaceSettingLayout,
    component: MemberSetting,
  },

  [PagePathEnum.TEAM_SETTING]: {
    parent: WorkspaceSettingLayout,
    component: TeamSetting,
  },
  [PagePathEnum.WORKSPACE_SETTING]: {
    parent: WorkspaceSettingLayout,
    component: WorkspaceSetting,
  },
  [PagePathEnum.SCENE]: {
    parent: RootLayout,
    component: App,
    // paramConfig: {
    //   sceneId: {
    //     validate: (value: string) => isValidNanoId(value),
    //   },
    // },
  },
  [PagePathEnum.PUBLIC_SCENCE]: {
    parent: RootLayout,
    component: App,
  },
  [PagePathEnum.WORKSPACE_COLECTION]: {
    parent: RootLayout,
    component: WorkSpace,
  },
  [PagePathEnum.MY_PROFILE]: {
    parent: AccountLayout,
    component: MyProfile,
  },
  [PagePathEnum.MY_WORKSPACE]: {
    parent: AccountLayout,
    component: MyWorkspace,
  },
  [PagePathEnum.PREFERENCE]: {
    parent: Preference,
    component: App,
  },
};
