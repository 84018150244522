import { useSetAtom } from "jotai";
import {
  authUserAtom,
  collectionIdAtom,
  sceneIdAtom,
  visibleLeftSidebarAtom,
  workspaceAtom,
} from "../../app-jotai";
import { workspaceService } from "../services";
import type { IAuthUser } from "../model";
import { useParams } from "../utils";

export default function useSetAuthUser() {
  const setAuthUser = useSetAtom(authUserAtom);
  const setWorkspaceAtom = useSetAtom(workspaceAtom);
  const setVisibleLeftSidebarAtom = useSetAtom(visibleLeftSidebarAtom);
  const setCollectionId = useSetAtom(collectionIdAtom);
  const setScenceId = useSetAtom(sceneIdAtom);
  const { id: workspaceId, collectionId, sceneId } = useParams();

  return async (user: IAuthUser) => {
    if (user) {
      setAuthUser(user);
      const { data: workspace } = await workspaceService.getUserWorkspace({
        workspaceId,
        collectionId,
        sceneId,
      });
      if (workspace) {
        setWorkspaceAtom(workspace);
        localStorage.setItem("workspaceId", workspace.id);
        if (collectionId) {
          setCollectionId(collectionId);
        }
        if (sceneId) {
          const scene = workspace?.collections
            ?.flatMap((collection) => collection.scenes)
            ?.find((scene) => scene?.id === sceneId);
          if (scene) {
            setCollectionId(scene.collectionId);
            setScenceId(sceneId);
          }
        }
        return;
      }
      setWorkspaceAtom(null);
      localStorage.removeItem("workspaceId");
      setVisibleLeftSidebarAtom(false);
    }
  };
}
