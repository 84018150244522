export enum WorkspaceRoleEnum {
  ADMIN,
  MEMBER,
}

export function mapWorkspaceRoleToText(role: WorkspaceRoleEnum) {
  switch (role) {
    case WorkspaceRoleEnum.ADMIN:
      return "admin";
    case WorkspaceRoleEnum.MEMBER:
      return "member";
    default:
      return "unidentified";
  }
}
