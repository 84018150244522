import clsx from "clsx";
import { DashboardIcon } from "../../../packages/excalidraw/components/icons";
import { WorkSpaceToolBar } from "./components/WorkSpaceToolBar";
import EmptyCollection from "./components/EmptyCollection";
import ScenceItem from "./components/ScenceItem";
import { useAtomValue } from "jotai";
import { sencesAtom, privateCollectionAtom } from "../../app-jotai";
import { formatDistanceToNow } from "date-fns";

export function WorkSpace() {
  const scenes = useAtomValue(sencesAtom);
  const privateCollection = useAtomValue(privateCollectionAtom);
  return (
    <div
      className={clsx(
        "box-border flex h-full w-full flex-col overflow-auto p-3 transition-[margin] bg-surface-low mt-0 px-12 pb-12 pt-6",
      )}
    >
      <h2
        className={clsx(
          "mb-3 mt-4 flex flex-col justify-center text-xl sm:text-2xl",
        )}
      >
        <div className="flex it pems-center justify-between">
          <div>
            <div className="flex items-center font-bold">
              <DashboardIcon className="mr-3 h-7 w-7 Icon" />
              Dashboard
            </div>
          </div>
          <div>
            <div className="w-fit" data-state="closed">
              <button
                type="button"
                className="flex items-center justify-center font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] px-4 py-2 text-sm bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
              >
                Start drawing
              </button>
              {/* <SortButton /> */}
            </div>
          </div>
        </div>
      </h2>
      <div className="h-[1px] w-full flex-auto flex-shrink-0 flex-grow-0 bg-gray-30"></div>
      <WorkSpaceToolBar />
      {/* <h3 className="my-2 mb-0 mt-1 text-lg font-bold text-primary">
        Recently modified by you
      </h3> */}
      <div className="grid gap-3 xs:gap-6 grid-cols-[repeat(auto-fill,_minmax(165px,_1fr))] xl:grid-cols-[repeat(auto-fill,_minmax(210px,_1fr))] mt-6 mt-1">
        {scenes.map((scene) => (
          <ScenceItem
            id={scene.id}
            key={scene.id}
            creator={scene.creator?.email || ""}
            isPrivate={privateCollection.id === scene.collectionId}
            lastUpdated={
              scene.updatedAt
                ? formatDistanceToNow(scene.updatedAt, {
                    addSuffix: true,
                  })
                : ""
            }
            name={scene.name}
          />
        ))}
      </div>
      <h3 className="my-2 mb-0 mt-1 text-lg font-bold text-primary">
        Recently visited by you
      </h3>
      <div className="grid gap-3 xs:gap-6 grid-cols-[repeat(auto-fill,_minmax(165px,_1fr))] xl:grid-cols-[repeat(auto-fill,_minmax(210px,_1fr))] mt-6 mt-1">
        <div className="col-span-full mb-6 ml-6 mt-10 flex flex-col">
          <div>
            <div className="font-drawn text-lg text-gray-50">
              Nothing to show yet
            </div>
          </div>
        </div>
      </div>
      <EmptyCollection />
    </div>
  );
}
