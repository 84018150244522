import * as Popover from "@radix-ui/react-popover";
import WorkspaceItem from "../WorkspaceItem";
import { useState } from "react";
import { useAtomValue } from "jotai";
import { workspaceAtom, workspaceListAtom } from "../../app-jotai";
import { CreateWorkspaceModal } from "../Modals/CreateWorkspaceModal";
import { Avatar } from "../../../packages/excalidraw/components/Avatar";

export function WorkspaceSelector() {
  const [openModal, setOpen] = useState(false);
  const onOpenChange = () => {
    setOpen(!openModal);
  };
  const workspaces = useAtomValue(workspaceListAtom);
  const workspace = useAtomValue(workspaceAtom);

  return (
    <>
      <Popover.Root>
        <Popover.Trigger asChild>
          <div
            id="workspace-select__container"
            className="relative w-full min-w-0"
          >
            <button
              type="button"
              id="workspace-select__trigger"
              aria-haspopup="menu"
              aria-expanded="false"
              data-state="closed"
              className="max-w-full m-0 p-0 bg-inherit active:bg-inherit rounded-none border-solid border-surface-high md-lg:border-b hover:bg-inherit focus:!outline-none focus-visible:!outline-none group w-full"
            >
              <div className="relative flex w-full min-w-0 select-none items-center px-4 py-3">
                <div className="flex w-full min-w-0 items-center">
                  {/* <img
                    className="mr-3 h-10 w-10 rounded-full"
                    alt="test"
                    src="/letter-avatars/t_indigo.png"
                  /> */}
                  <div className="mr-3 ">
                    <Avatar
                      color=""
                      onClick={() => {}}
                      className="h-10 w-10 rounded-full bg-primary hover:bg-brand-hover focus-visible:bg-brand-hover active:bg-brand-active text-center text-base font-bold text-surface-lowest focus:outline-none"
                      name={workspace?.name || ""}
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </div>

                  <div className="w-full min-w-0 font-primary text-base font-bold leading-tight text-color-text group-hover:text-primary">
                    <div className="relative flex w-full flex-col text-left">
                      <div className="w-full overflow-hidden text-ellipsis">
                        {workspace?.name}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative left-2 flex justify-center p-2 text-base text-color-text group-hover:text-primary">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className=" Icon "
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M4.22 8.47a.75.75 0 0 1 1.06 0L12 15.19l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L4.22 9.53a.75.75 0 0 1 0-1.06Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
          </div>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            id="workspace-select__content"
            className="flex flex-col w-full max-w-[260px] bg-white dark:bg-[#1B1B1F] scrollbar-sm max-h-[85vh] overflow-y-auto z-50 select-none relative box-border"
          >
            <div
              style={{
                width: "260px",
              }}
            >
              <div role="group" className="py-2">
                <span className="mb-1 block px-4 text-xs font-semibold">
                  Your workspaces
                </span>

                <div
                  role="separator"
                  aria-orientation="horizontal"
                  className="h-px bg-gray-20"
                ></div>

                <div className="p-2">
                  <div className="w-full">
                    {workspaces.map((w) => (
                      <WorkspaceItem
                        key={w.id}
                        id={w.id}
                        name={w.name}
                        role={w.userRole}
                        thumbnail="/letter-avatars/t_indigo.png"
                        isSelected={w.id === workspace?.id}
                      />
                    ))}
                    <div
                      role="menuitem"
                      className="bg-primary hover:bg-brand-hover focus-visible:bg-brand-hover active:bg-brand-active mx-4 mb-2 mt-4 px-4 py-2 cursor-pointer text-center text-xs font-bold text-surface-lowest rounded focus:outline-none"
                      tabIndex={-1}
                      onClick={() => setOpen(true)}
                    >
                      + Create workspace
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <CreateWorkspaceModal open={openModal} onOpenChange={onOpenChange} />
    </>
  );
}
