import type { BaseResponse } from "../http/base";
import { AbstractApiService } from "../http/base";
import type { IAuthUser } from "../model";
import type { GoogleAuthorizeCodeDto } from "./types";

class AuthService extends AbstractApiService {
  async getAuthInfo(): Promise<BaseResponse<IAuthUser>> {
    return this.getApi<IAuthUser>("/auth/info");
  }

  async loginGoogle(payload: GoogleAuthorizeCodeDto): Promise<void> {
    const { data, error } = await this.postApi<
      GoogleAuthorizeCodeDto,
      { accessToken: string }
    >("/auth/google", payload);
    if (error) {
      return alert(JSON.stringify(error));
    }
    if (data?.accessToken) {
      localStorage.setItem("accessToken", data.accessToken);
    }
  }

  async logout(): Promise<void> {
    await this.postApi<null, void>("/auth/logout");
  }
}

export const authService = new AuthService();
