import { useEffect, useMemo, useState } from "react";
import RouterContext from "./RouterContext";
import {
  PagePathEnum,
  PUBLIC_PATHS,
  ROUTE_CONFIG,
  WOKSPACE_PATHS,
} from "./router";
import type { TNavigate } from "./types";
import { useMatchRoute } from "./utils";
import { useAtom, useAtomValue } from "jotai";
import { visibleLeftSidebarAtom, workspaceAtom } from "../../app-jotai";
import useSetSelectCollection from "../../share/hook/useSetSelectCollection";
import { useSelectedItem } from "../../share/hook/useSelectedItem";
import { SIDEBAR_ITEM } from "../../share/constants";
import { authService } from "../../share/services";
import useSetAuthUser from "../../share/hook/useSetAuthUser";

export default function RouterComponent({
  path = window.location.pathname,
}: {
  path?: string;
}) {
  const [isRenderChild, setIsRenderChild] = useState(false);
  const [currentPath, setCurrentPath] = useState(path);
  const [isShowLeftSidebar, setIsShowLeftSidebar] = useAtom(
    visibleLeftSidebarAtom,
  );
  const setSelectCollection = useSetSelectCollection();
  const { setSelectedItem } = useSelectedItem();
  const [pathStates, setPathStates] = useState<Record<string, any>>({});
  const { matchPath, params } = useMatchRoute(currentPath);
  const setAuthUser = useSetAuthUser();
  const workspace = useAtomValue(workspaceAtom);
  const navigate: TNavigate = (newPath: string, config) => {
    setCurrentPath(newPath);
    if (config?.states) {
      setPathStates({
        [newPath]: config.states,
      });
    } else {
      setPathStates({});
    }
    window.history.pushState(null, "", newPath);
  };
  const { component: Component, parent: Parent } = useMemo(
    () => ROUTE_CONFIG[matchPath],
    [matchPath],
  );
  useEffect(() => {
    (async () => {
      const { data, status } = await authService.getAuthInfo();
      if ((!data || status === 401) && !PUBLIC_PATHS.includes(matchPath)) {
        navigate(PagePathEnum.SIGN_IN);
        return;
      }
      if (data) {
        setAuthUser(data);
      }
      setIsRenderChild(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      workspace &&
      [PagePathEnum.SIGN_IN, PagePathEnum.HOME].includes(
        window.location.pathname as PagePathEnum,
      )
    ) {
      navigate(`/w/${workspace.id}`);
    }
  }, [workspace]);
  useEffect(() => {
    if (WOKSPACE_PATHS.includes(matchPath)) {
      if (!isShowLeftSidebar) {
        setIsShowLeftSidebar(true);
      }
      if (matchPath === PagePathEnum.WORKSPACE) {
        setSelectedItem(SIDEBAR_ITEM.DASHBOARD);
      }
      if (
        matchPath === PagePathEnum.WORKSPACE_COLECTION &&
        params.collectionId
      ) {
        setSelectCollection(params.collectionId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchPath]);

  return (
    <RouterContext.Provider
      value={{
        currentPath,
        navigate,
        pathStates,
      }}
    >
      {isRenderChild ? (
        Parent ? (
          <Parent>
            <Component />
          </Parent>
        ) : (
          <Component />
        )
      ) : null}
    </RouterContext.Provider>
  );
}
