import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import { collectionService } from "../../share/services";
import { useAtom, useAtomValue } from "jotai";
import { toastAtom, workspaceIdAtom } from "../../app-jotai";
import useRefreshWorkspace from "../../share/hook/useRefreshWorkspace";

export function CreateCollectionModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const workspaceId = useAtomValue(workspaceIdAtom);
  const refreshWorkspace = useRefreshWorkspace();

  const [toast, setToast] = useAtom(toastAtom);
  const [value, setValue] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);
  const onSubmit = async () => {
    if (!value) {
      setToast({
        ...toast,
        isShow: true,
        message: "Please enter a collection name",
      });
    }
    if (!workspaceId) {
      alert("Workspace not found");
      return;
    }
    const { error } = await collectionService.create({
      name: value,
      workspaceId,
    });

    if (error) {
      alert("Failed to create collection");
    }
    await refreshWorkspace();
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <div
          className="ModalDialog scrollbar-sm z-20"
          role="dialog"
          aria-modal="true"
          aria-labelledby="Create collection"
        >
          <Dialog.Overlay className="ModalDialog-background" />
          <Dialog.Content
            className="ModalDialog-content x-custom-scrollbar bg-white dark:bg-surface-low"
            style={{
              maxWidth: "450px",
            }}
          >
            <Dialog.Title className="ModalDialog-title mb-2 max-w-full text-ellipsis">
              <span className="text-lg font-bold text-color-text sm:text-xl">
                Create collection
              </span>
            </Dialog.Title>
            <form
              className="FormComponent-form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="FormComponent-row gap-x-6">
                <div className="FormComponent-item !mr-0 w-auto">
                  <label htmlFor="emoji-picker-btn" className="font-bold">
                    Icon
                  </label>
                  <button
                    type="button"
                    aria-haspopup="dialog"
                    aria-expanded="false"
                    aria-controls="radix-:r4a:"
                    data-state="closed"
                    id="emoji-picker-btn"
                    className="form-emoji-trigger m-0 h-[42px] w-[42px]"
                  >
                    <div className="flex h-full w-full items-center justify-center text-xl">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="img"
                        className=" Icon "
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M20.496 5.627A2.25 2.25 0 0 1 22 7.75v10A4.25 4.25 0 0 1 17.75 22h-10a2.25 2.25 0 0 1-2.123-1.504l2.097.004H17.75a2.75 2.75 0 0 0 2.75-2.75v-10l-.004-.051V5.627ZM17.246 2a2.25 2.25 0 0 1 2.25 2.25v12.997a2.25 2.25 0 0 1-2.25 2.25H4.25A2.25 2.25 0 0 1 2 17.247V4.25A2.25 2.25 0 0 1 4.25 2h12.997Zm0 1.5H4.25a.75.75 0 0 0-.75.75v12.997c0 .414.336.75.75.75h12.997a.75.75 0 0 0 .75-.75V4.25a.75.75 0 0 0-.75-.75Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
                <div className="FormComponent-item ">
                  <label htmlFor="ias" className="font-bold">
                    Collection name
                  </label>
                  <input
                    className="x-text-size-inherit h-full border-0 shadow-sm"
                    type="text"
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                  />
                  <p className="FormComponent-error-message">&nbsp;</p>
                </div>
              </div>
              <div className="FormComponent-row mt-3 justify-end">
                <Dialog.Close asChild>
                  <button
                    type="button"
                    className="flex items-center justify-center border-[1px] border-transparent bg-transparent hover:!bg-surface-high active:!bg-surface-high disabled:text-border-outline-variant disabled:hover:!bg-transparent text-primary hover:text-primary-darker rounded-md px-6 py-2.5 text-base font-bold media-xs:order-2 active:scale-[.98]"
                  >
                    Cancel
                  </button>
                </Dialog.Close>
                <button
                  type="button"
                  className="flex items-center justify-center px-8 py-2.5 text-base font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
                  onClick={onSubmit}
                >
                  <div className="relative">
                    <div className="">Create collection</div>
                  </div>
                </button>
              </div>
            </form>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
