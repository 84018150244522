export default function Notification() {
  return (
    <div
      id="scroll-container"
      className="box-border flex h-full w-full flex-col overflow-auto p-3 transition-[margin] bg-surface-low mt-0 px-12 pb-12 pt-6"
    >
      <h2 className="mb-3 mt-4 flex flex-col justify-center text-xl sm:text-2xl">
        <div className="flex items-center">Notifications</div>
      </h2>

      <div className="h-[1px] w-full flex-auto flex-shrink-0 flex-grow-0 bg-gray-30"></div>

      <div className="flex grow flex-col">
        <div className="flex grow flex-col items-center justify-center text-gray-60 undefined">
          <div className="pointer-events-none mb-4 select-none opacity-10 dark:opacity-60">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="384.801"
              height="161.278"
            >
              <path
                d="M10 137.167c10.604 2.556 41.416 14.182 63.261 14.112 21.846-.07 48.739-3.874 67.831-14.518 19.092-10.634 40.394-35.911 46.715-49.344 6.32-13.433.533-26.78-8.789-31.273-9.322-4.492-35.02-2.242-47.136 4.32-12.114 6.55-26.797 25.755-25.542 35.02 1.256 9.256 15.062 17.671 33.098 20.536 18.027 2.853 50.058 5.501 75.094-3.413 25.036-8.914 62.472-41.838 75.11-50.062"
                stroke="#343a40"
                stroke-width="2.5"
                fill="none"
                stroke-dasharray="8 10"
                stroke-linecap="round"
              />
              <path
                d="M270.902 25.6c23.05-1.56 41.94-8 102.45-15.6m-101.89 16.57c28.29-5.22 56.72-8.39 101.35-15.32m-.6.29c-12.36 11.13-23.86 26.52-43.59 45.04m45.3-46.17c-17.94 18.74-34.87 35.48-43.44 44.5m1.48-1.74c-6.36-2.01-12.52-1.52-25.16-6.79m22.77 8.23c-4.72-2.33-8.13-4.69-20.03-9.34m.37.48c-2.63 3.25-5.68 5.9-9.61 11.36m7.51-12.62c-1.51 3.59-2.89 6.06-8.45 12.88m-1.17 2.46c.07-7.77-4.39-16.41-5.69-23.57m6.86 21.44c-1.31-6.78-3.91-13.48-6.77-21.02m-.01-1.31c-3.59-2.53-11.88-4.81-21.92-9.81m22.78 11.11c-6.11-2.58-10.64-5.39-21.14-11.28"
                stroke="#343a40"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
              />
              <path
                d="M308.379 45.89c25.63-13.58 46.37-26.03 65.17-35.42m-64.2 33.21c22.81-12.28 48.25-24.68 65.45-32.92M292.952 37.403c22.49-11.01 46.3-17.18 77.5-25.64m-79.71 23.27c16.91-4.97 33.82-9.72 79.99-23.74"
                stroke="#343a40"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <span className="font-drawn text-lg">
            You currently don't have any notifications to review.
          </span>
        </div>

        <div className="mx-4 my-8 md:mx-0">
          <div className="infinite-scroll-component__outerdiv">
            <div
              className="infinite-scroll-component "
              style={{ height: "auto", overflow: "auto" }}
            >
              <div className="ml-12 flex pb-4 font-drawn text-gray-40 sm:pb-8">
                No more notifications
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
