import * as Dialog from "@radix-ui/react-dialog";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { permissionAtom, sceneIdAtom } from "../../app-jotai";
import { publicService } from "../../share/services/public.service";
import { ShareIcon } from "../../../packages/excalidraw/components/icons";
import Copied from "../Copied";
import useChangePermission from "../../share/hook/useChangePermission";

const Skeleton = () => (
  <div className="mt-6 flex flex-col gap-6 invisible ">
    <div>
      <div className="flex w-32 h-4 backdrop-blur-lg bg-gray-20 animate-pulse mt-3 rounded-md !mt-0 hidden w-[4ch] sm:block"></div>
      <div className="w-7 h-6 rounded-md bg-gray-20 animate-pulse mt-3 !mt-0 !h-10 w-full sm:!mt-2 sm:!h-12"></div>
    </div>
    <div>
      <div className="flex w-32 h-4 backdrop-blur-lg bg-gray-20 animate-pulse mt-3 rounded-md !mt-0 w-[20ch]"></div>
      <div className="w-7 h-6 rounded-md bg-gray-20 animate-pulse mt-3 !mt-2 !h-5 w-1/4 sm:w-1/2"></div>
      <div className="w-7 h-6 rounded-md bg-gray-20 animate-pulse mt-3 !mt-2 !h-5 w-1/4 sm:hidden"></div>
    </div>
  </div>
);

const Body = () => {
  const sceneId = useAtomValue(sceneIdAtom);
  const [permission, setPermission] = useAtom(permissionAtom);
  const [copied, setCopied] = useState(false);
  const changePermission = useChangePermission();
  const onEdit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!sceneId) {
      return;
    }
    const value = e.target.checked ? 1 : 0;
    const { error } = await changePermission({
      value,
      active: true,
    });

    if (!error) {
      setPermission({ value });
    }
  };
  const shareLink = () => {
    return `${window.location.origin}/p/s/${sceneId}`;
  };
  const onCopy = () => {
    setCopied(true);
    (async () => {
      await navigator.clipboard.writeText(shareLink());
    })();
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="user-select-none mt-6 flex flex-col gap-6">
      <div>
        <div className="flex flex-col xs:col-span-3">
          {/* <button
            type="button"
            className="flex items-center justify-center px-8 py-2.5 font-semibold rounded-md disabled:bg-surface-low disabled:text-border-outline-variant text-surface-lowest active:shadow-none [--circle-stroke-color:var(--surface-lowest)] disabled:[--circle-stroke-color:var(--on-surface)] w-full text-sm xs:hidden bg-primary hover:bg-brand-hover active:bg-brand-active active:scale-[.98]"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              className="mr-2 Icon "
              viewBox="0 0 24 24"
              fill="none"
              stroke-width="2"
              stroke="currentColor"
              stroke-linecap="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M9 15l6 -6"></path>
              <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path>
              <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"></path>
            </svg>
            Copy link
          </button> */}

          <div className="hidden xs:block">
            <div className="mb-1 flex justify-between">
              <div className="text-sm font-semibold">Link</div>
              {copied && <Copied />}
            </div>
            <div className="relative flex">
              <div className="flex h-12 w-full cursor-pointer items-center rounded-lg bg-surface-high py-0 pl-4 text-base pr-12">
                <div className="truncate">{sceneId ? shareLink() : ""}</div>
              </div>

              <div className="absolute bottom-0 right-0 top-0 mr-4 flex items-center">
                <div className="w-fit">
                  <button
                    type="button"
                    className="flex items-center justify-center p-2 rounded cursor-pointer text-xl text-on-surface bg-transparent disabled:text-border-outline-variant disabled:hover:!bg-transparent hover:!bg-primary-light active:!bg-primary-light"
                    onClick={onCopy}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="img"
                      className=" Icon "
                      viewBox="0 0 24 24"
                      fill="none"
                      strokeWidth="2"
                      stroke="currentColor"
                      strokeLinecap="round"
                    >
                      <rect
                        x="9"
                        y="9"
                        width="13"
                        height="13"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-sm font-semibold text-gray-80">
        <div className="mb-1">Anyone with link can:</div>
        <div className="flex flex-col gap-x-12 gap-y-2 text-base font-normal xs:flex-row">
          <input
            className="ToggleButton ToggleButton-right"
            type="checkbox"
            checked={true}
            disabled={true}
          />
          <label
            htmlFor="input-checkbox-2"
            style={{
              transform: "translateY(-1px)",
            }}
          >
            <div
              style={{
                transform: "translateY(-1px)",
              }}
            >
              <div className="ml-2">View</div>
            </div>
          </label>
          <input
            className="ToggleButton ToggleButton-right"
            type="checkbox"
            id="input-checkbox-3"
            checked={permission?.value === 1}
            onChange={onEdit}
          />
          <label
            htmlFor="input-checkbox-3"
            style={{
              transform: "translateY(-1px)",
            }}
          >
            <div
              style={{
                transform: "translateY(-1px)",
              }}
            >
              <div className="ml-2">Edit</div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};
export default function ShareModal() {
  const [isLoading, setIsLoading] = useState(false);
  const sceneId = useAtomValue(sceneIdAtom);
  const [isShared, setIsShared] = useState(false);
  const setPermission = useSetAtom(permissionAtom);
  const changePermission = useChangePermission();
  const onShare = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!sceneId) {
      return;
    }
    setIsLoading(true);
    const active = e.target.checked;
    const { data } = await changePermission({ active });
    const permission = data ? { value: data.value } : null;
    setPermission(permission);
    setIsLoading(false);
    setIsShared(active);
  };

  useEffect(() => {
    if (!sceneId) {
      return;
    }
    (async () => {
      const { data } = await publicService.getScencePermissions(sceneId);
      if (data) {
        setIsShared(true);
      }
    })();
  }, [sceneId]);

  return (
    <Dialog.Root>
      <Dialog.Trigger
        className="excalidraw-button !border-0 shadow-[0_0_0_1px_var(--box-shadow-normal)] active:shadow-[--box-shadow-active] collab-btn !bg-primary !text-surface-lowest hover:!bg-brand-hover active:!bg-brand-active"
        style={
          {
            background: "var(--color-surface-low)",
            width: "var(--lg-button-size)",
            height: "var(--lg-button-size)",
            boxShadow: "var(--color-surface-lowest)",
            "--box-shadow-active": "var(--color-brand-active)",
          } as React.CSSProperties
        }
      >
        <ShareIcon />
      </Dialog.Trigger>

      <Dialog.Portal>
        <div className="ModalDialog scrollbar-sm sharing-modal z-20">
          <Dialog.Overlay className="ModalDialog-background" />
          <Dialog.Content
            className="ModalDialog-content x-custom-scrollbar bg-white dark:bg-surface-low"
            style={{
              width: "544px",
            }}
          >
            <Dialog.Title className="ModalDialog-title">Share</Dialog.Title>
            <div
              className="absolute right-5 top-5 flex cursor-pointer p-2 top-3 right-3 w-6 h-6"
              aria-label="Close dialog"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                className=" Icon "
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="m4.397 4.554.073-.084a.75.75 0 0 1 .976-.073l.084.073L12 10.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L13.061 12l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L12 13.061l-6.47 6.47a.75.75 0 0 1-1.06-1.061L10.939 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084-.073.084Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>

            <div dir="ltr">
              <div
                role="tablist"
                className="mb-6 flex gap-x-5 border-b border-gray-20 px-6 xs:px-10"
                style={{
                  outline: "none",
                }}
              >
                <button
                  type="button"
                  className="sharing-modal__tab-trigger h-12 text-sm font-semibold text-gray-60 rounded-none data-[state=active]:text-primary hover:bg-transparent hover:text-primary active:bg-transparent"
                >
                  Share scene
                  <div
                    style={{
                      background: "var(--color-primary)",
                      borderStartEndRadius: "3px",
                      borderStartStartRadius: "3px",
                      bottom: 0,
                      content: "",
                      height: "3px",
                      left: 0,
                      position: "absolute",
                      right: 0,
                    }}
                  />
                </button>
              </div>

              <div className="px-6 xs:px-10">
                <div>
                  <div className="">
                    <input
                      className="ToggleButton ToggleButton-left"
                      type="checkbox"
                      id="input-checkbox-1"
                      checked={isShared}
                      onChange={onShare}
                    />

                    <label
                      htmlFor="input-checkbox-1"
                      style={{
                        transform: "translateY(-1px)",
                      }}
                    >
                      <div
                        style={{
                          transform: "translateY(-1px)",
                        }}
                      >
                        <div className="relative flex items-center justify-between text-sm font-semibold text-gray-80">
                          <span className="mr-1">
                            Shareable link collaboration
                          </span>

                          <span className="mr-2 text-base font-normal">
                            Enabled
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>

                  {isLoading ? <Skeleton /> : null}
                  {isShared ? <Body /> : null}
                </div>
              </div>

              <div className="px-6 xs:px-10"></div>

              <div className="px-6 xs:px-10"></div>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
