import { createRoot } from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import "../excalidraw-app/sentry";
import RouterComponent from "./pages/_routing/RouterComponent";
import RootProvider from "./share/provider/RootProvider";
window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
registerSW();
root.render(
  <RootProvider>
    <RouterComponent />
  </RootProvider>,
);
