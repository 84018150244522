import type { ICollection } from "../model";
import type { BaseResponse } from "../http/base";
import { AbstractApiService } from "../http/base";
import type { CreateCollectionDto } from "./types";

class CollectionService extends AbstractApiService {
  async create(
    payload: CreateCollectionDto,
  ): Promise<BaseResponse<ICollection>> {
    return this.postApi<CreateCollectionDto, ICollection>(
      "/collections",
      payload,
    );
  }

  async getWokspaceCollections(
    workspaceId: string,
  ): Promise<BaseResponse<ICollection[]>> {
    return this.getApi<ICollection[]>(`workspaces/${workspaceId}/collections`);
  }
}
export const collectionService = new CollectionService();
